import React, {useContext, useState} from 'react';
import Context from '../../context/ContextProvider';
import btnReiniciar from '../../images/btn_reiniciar.png';
import btnDescargar from '../../images/btn_descargar.png';
import * as svg from 'save-svg-as-png';

import './Botones.scss'

const DibujoPrueba = () => {

    const stateColor = useContext(Context);
    const { colorObtenido } = stateColor;
    const [cambioImagen, setcambioImagen] = useState(true);

    

    let btnAnterior = 0;
    let btnActual = 0;

    const obtenetID = (e) =>{
        e.target.style.fill = colorObtenido;
        console.log(e.target.id);            
    }
    

    const reiniciar = () => {
        window.location.reload();
    }

    const download = () => {
        svg.saveSvgAsPng(document.getElementById('Capa_1'), 'artesania.png');
    };

    const valorPunto = (e) => {
        const valor = parseInt(e.target.id);
        btnActual = valor;  
        
        console.log('Click numero: ' + valor);

        if(valor === 1){
            if(btnAnterior === 0){
                console.log('Desde anterior = 0');
                btnAnterior = valor;
            }
        }

        if (valor !== 1) {
            if(btnAnterior === (btnActual -1)){
                console.log('linea'+btnAnterior+btnActual);
                document.getElementById('linea'+btnAnterior+btnActual).style.stroke = "#000000";
                
                if(btnAnterior === 29 && btnActual === 30){
                    setcambioImagen(false);
                }
                btnAnterior = btnAnterior + 1;
            }
        }        
    }

        
    return (
        <div className='div-dibujo'>
            {
                cambioImagen ?
                <svg    
                version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" 
                x="0px" y="0px"
                width="572px"
                height="558px" viewBox="0 0 572 558" 
                // onClick={obtenetID}
            >
                <g id="Capa_1">
                    <path fill="#FFFFFF" d="M309.125,353.405c-1.376-1.447-8.814-5.365-8.814-5.365c22.475,25.33,19.794,69.021,19.678,70.841
                        c0-2.31-4.594-5.433-4.594-5.433c2.75,19.12-10.213,44.024-23.949,60.418c-4.684,5.62-9.466,10.234-13.733,13.269
                        c-16.693,11.869-35.629,42.041-35.629,42.041c-6.157-28.565-31.902-45.023-48.109-60.139c-4.148-3.871-7.67-7.672-10.097-11.541
                        c-11.82-19.052-9.022-41.53-9.022-41.53c-2.378,1.354-4.85,6.925-4.85,6.925c-3.148-51.743,14.828-66.875,14.828-66.875
                        c-3.451,0.396-11.215,5.876-11.215,5.876c1.19-12.663,12.081-41.063,19.799-51.325c16.838-22.407,20.846-41.599,20.846-41.599
                        c9.165,4.079,18.748,8.555,28.749,13.478h0.025c3.335,4.499,11.053,6.787,19.401,5.246c8.859-1.658,15.552-7.089,16.489-12.872
                        c1.047,9.562,8.859,36.96,19,51.558C309.495,343.05,309.125,353.405,309.125,353.405"/>
                    <path fill="#FFFFFF" stroke="#000000" d="M309.125,353.405c-1.376-1.447-8.814-5.365-8.814-5.365
                        c22.475,25.33,19.794,69.021,19.678,70.841c0-2.31-4.594-5.433-4.594-5.433c2.75,19.12-10.213,44.024-23.949,60.418
                        c-4.684,5.62-9.466,10.234-13.733,13.269c-16.693,11.869-35.629,42.041-35.629,42.041c-6.157-28.565-31.902-45.023-48.109-60.139
                        c-4.148-3.871-7.67-7.672-10.097-11.541c-11.82-19.052-9.022-41.53-9.022-41.53c-2.378,1.354-4.85,6.925-4.85,6.925
                        c-3.148-51.743,14.828-66.875,14.828-66.875c-3.451,0.396-11.215,5.876-11.215,5.876c1.19-12.663,12.081-41.063,19.799-51.325
                        c16.838-22.407,20.846-41.599,20.846-41.599c9.165,4.079,18.748,8.555,28.749,13.478h0.025c3.335,4.499,11.053,6.787,19.401,5.246
                        c8.859-1.658,15.552-7.089,16.489-12.872c1.047,9.562,8.859,36.96,19,51.558C309.495,343.05,309.125,353.405,309.125,353.405z"/>
                    <path fill="#FFFFFF;" d="M257.889,263.097c3.224-0.615,6.347-0.635,9.143-0.192c6.25,1.002,11.005,4.359,11.873,9.023
                        c-0.142,0.557-0.121,1.541,0.023,2.89c-0.937,5.785-7.63,11.215-16.489,12.873c-8.348,1.541-16.066-0.747-19.402-5.246
                        c-0.816-1.068-1.348-2.287-1.609-3.588c-0.65-3.478,0.98-7.02,4.082-9.889C248.496,266.194,252.828,264.023,257.889,263.097"/>
                    <path fill="#FFFFFF" stroke="#000000" d="M257.889,263.097c3.224-0.615,6.347-0.635,9.143-0.192
                        c6.25,1.002,11.005,4.359,11.873,9.023c-0.142,0.557-0.121,1.541,0.023,2.89c-0.937,5.785-7.63,11.215-16.489,12.873
                        c-8.348,1.541-16.066-0.747-19.402-5.246c-0.816-1.068-1.348-2.287-1.609-3.588c-0.65-3.478,0.98-7.02,4.082-9.889
                        C248.496,266.194,252.828,264.023,257.889,263.097z"/>
                    <path fill="#FFFFFF;" d="M278.904,271.928c-0.868-4.664-5.623-8.021-11.873-9.023l0.025-0.21
                        c0,0,15.433-50.486,56.895-85.974c40.457-34.654,110.646-15.413,113.91-14.479c-3.173-0.562-67.226-10.286-143.081,89.351
                        c-5.198,6.837-10.443,14.156-15.759,22.061C279.041,273.095,279.021,272.51,278.904,271.928"/>
                    <path fill="#FFFFFF" stroke="#000000" d="M278.904,271.928c-0.868-4.664-5.623-8.021-11.873-9.023
                        l0.025-0.21c0,0,15.433-50.486,56.895-85.974c40.457-34.654,110.646-15.413,113.91-14.479
                        c-3.173-0.562-67.226-10.286-143.081,89.351c-5.198,6.837-10.443,14.156-15.759,22.061
                        C279.041,273.095,279.021,272.51,278.904,271.928z"/>
                    <path fill="#FFFFFF" stroke="#000000" d="M19.369,244.971c0.979-1.419,27.797-38.823,97.818-36.795
                        c71.377,2.1,128.323,60.792,128.323,60.792c-3.102,2.869-4.732,6.412-4.082,9.889c0.261,1.301,0.793,2.52,1.609,3.588h-0.024
                        c-10.001-4.922-19.584-9.398-28.749-13.477c-9.421-4.196-18.399-7.974-26.955-11.357"/>
                    <path id="linea12" fill="#FFFFFF" stroke="#FFFFFF" d="M63.369,230.382c-32.258,1.676-43.303,13.787-44,14.589"/>
                    <path id="linea23" fill="#FFFFFF" stroke="#FFFFFF" d="M125.369,237.507c-26.307-6.344-46.578-7.927-62-7.125"/>
                    <path id="linea34" fill="#FFFFFF" stroke="#FFFFFF" d="M187.309,257.611c-23.645-9.361-44.169-15.819-61.939-20.104"/>
                    <path fill="#FFFFFF" d="M56.471,114.204c-23.159-32.413-31.786-69.557-29.85-72.637
                        c20.267,2.287,40.154,19.074,52.558,31.808c7.928,8.163,12.825,14.644,12.825,14.644C76.638,93.103,56.471,114.204,56.471,114.204"
                        />
                    <path fill="#FFFFFF" stroke="#000000" d="M56.471,114.204c-23.159-32.413-31.786-69.557-29.85-72.637
                        c20.267,2.287,40.154,19.074,52.558,31.808c7.928,8.163,12.825,14.644,12.825,14.644C76.638,93.103,56.471,114.204,56.471,114.204z
                        "/>
                    <path fill="#FFFFFF" d="M302.124,44.621c16.442-28.053,34.651-39.359,34.651-39.359s4.502,29.617-5.27,68.112
                        c-12.846-4.896-38.868-9.84-38.868-9.84C295.62,56.514,298.84,50.244,302.124,44.621"/>
                    <path fill="#FFFFFF" stroke="#000000" d="M302.124,44.621c16.442-28.053,34.651-39.359,34.651-39.359
                        s4.502,29.617-5.27,68.112c-12.846-4.896-38.868-9.84-38.868-9.84C295.62,56.514,298.84,50.244,302.124,44.621z"/>
                    <path fill="#FFFFFF;" d="M103.735,68.828c-11.051-4.294-24.556,4.547-24.556,4.547
                        C66.775,60.641,46.888,43.854,26.621,41.567c1.959-3.1,22.107-8.32,49.969,7.908C88.81,56.585,97.67,63.417,103.735,68.828"/>
                    <path fill="#FFFFFF" stroke="#000000" d="M103.735,68.828c-11.051-4.294-24.556,4.547-24.556,4.547
                        C66.775,60.641,46.888,43.854,26.621,41.567c1.959-3.1,22.107-8.32,49.969,7.908C88.81,56.585,97.67,63.417,103.735,68.828z"/>
                    <path fill="#FFFFFF" d="M274.82,48.842c10.425-16.739,34.02-48.173,61.956-43.58c0,0-18.209,11.306-34.652,39.359
                        c-1.493-0.372-12.499-2.725-26.884,4.479L274.82,48.842z"/>
                    <path fill="#FFFFFF" stroke="#000000" d="M274.82,48.842c10.425-16.739,34.02-48.173,61.956-43.58
                        c0,0-18.209,11.306-34.652,39.359c-1.493-0.372-12.499-2.725-26.884,4.479L274.82,48.842z"/>
                    <path fill="#FFFFFF" d="M391.295,539.137c-2.495,6.924-76.229-1.566-76.229-1.566"/>
                    <path id="linea1314" fill="#FFFFFF" stroke="#FFFFFF" d="M391.295,539.137c-2.495,6.924-76.229-1.566-76.229-1.566"/>
                    <path fill="#FFFFFF" d="M357.974,505.51c33.811,8.887,36.793,23.899,33.321,33.627"/>
                    <path id="linea1415" fill="#FFFFFF" stroke="#FFFFFF" d="M357.974,505.51c33.811,8.887,36.793,23.899,33.321,33.627"/>
                    <path fill="#FFFFFF" d="M315.066,537.57c1.399-1.051,2.334-2.38,2.639-4.033c0.675-3.523-0.354-6.248-2.267-8.562
                        c20.173-3.514,33.725-10.604,42.535-19.467"/>
                    <path fill="#FFFFFF" stroke="#000000" d="M315.066,537.57c1.399-1.051,2.334-2.38,2.639-4.033
                        c0.675-3.523-0.354-6.248-2.267-8.562c20.173-3.514,33.725-10.604,42.535-19.467"/>
                    <path fill="#FFFFFF" d="M315.066,537.57c-6.809,5.202-24.414,4.03-31.456,4.664c-8.467,0.77-41.391-0.839-41.391-0.839"/>
                    <path id="linea1213" fill="#FFFFFF" stroke="#FFFFFF" d="M315.066,537.57c-6.809,5.202-24.414,4.03-31.456,4.664
                        c-8.467,0.77-41.391-0.839-41.391-0.839"/>
                    <path fill="#FFFFFF" d="M242.22,541.396c1.493-0.442-0.137-12.221-0.137-12.221s18.936-30.173,35.629-42.041
                        c4.267-3.033,9.049-7.648,13.734-13.269c0,0-1.372,19.891,2.612,32.132c2.936,9.048,15.901,12.358,21.381,18.979
                        c1.913,2.313,2.939,5.037,2.265,8.561c-0.303,1.654-1.238,2.983-2.637,4.033"/>
                    <path fill="#FFFFFF" stroke="#000000" d="M242.22,541.396c1.493-0.442-0.137-12.221-0.137-12.221
                        s18.936-30.173,35.629-42.041c4.267-3.033,9.049-7.648,13.734-13.269c0,0-1.372,19.891,2.612,32.132
                        c2.936,9.048,15.901,12.358,21.381,18.979c1.913,2.313,2.939,5.037,2.265,8.561c-0.303,1.654-1.238,2.983-2.637,4.033"/>
                    <path fill="#FFFFFF" d="M242.22,541.396c-1.465,0.42-30.943,0.301-48.499,1.352c-7.695,0.442-13.923-0.142-18.586-1.726"/>
                    <path id="linea1112" fill="#FFFFFF" stroke="#FFFFFF" d="M242.22,541.396c-1.465,0.42-30.943,0.301-48.499,1.352
                        c-7.695,0.442-13.923-0.142-18.586-1.726"/>
                    <path fill="#FFFFFF" d="M175.135,541.022c-5.995-1.981-9.446-5.597-10.142-10.61c-0.307-2.119,0.582-4.151,2.194-6.084
                        c5.126-6.202,17.696-11.425,23.151-15.952c7.179-5.967,3.636-39.339,3.636-39.339c16.208,15.115,41.953,31.573,48.11,60.139
                        c0,0,1.629,11.778,0.136,12.221"/>
                    <path fill="#FFFFFF" stroke="#000000" d="M175.135,541.022c-5.995-1.981-9.446-5.597-10.142-10.61
                        c-0.307-2.119,0.582-4.151,2.194-6.084c5.126-6.202,17.696-11.425,23.151-15.952c7.179-5.967,3.636-39.339,3.636-39.339
                        c16.208,15.115,41.953,31.573,48.11,60.139c0,0,1.629,11.778,0.136,12.221"/>
                    <path fill="#FFFFFF" d="M175.135,541.045c0,0-77.696,4.827-79.892-2.215"/>
                    <path id="linea1011" fill="#FFFFFF" stroke="#FFFFFF" d="M175.135,541.045c0,0-77.696,4.827-79.892-2.215"/>
                    <path fill="#FFFFFF" d="M130.411,506.14c8.323,8.184,20.216,13.966,36.775,18.188c-1.611,1.935-2.5,3.966-2.193,6.084
                        c0.695,5.016,4.147,8.629,10.142,10.611v0.021"/>
                    <path fill="#FFFFFF" stroke="#000000" d="M130.411,506.14c8.323,8.184,20.216,13.966,36.775,18.188
                        c-1.611,1.935-2.5,3.966-2.193,6.084c0.695,5.016,4.147,8.629,10.142,10.611v0.021"/>
                    <line fill="#FFFFFF" x1="129.92" y1="506.63" x2="130.411" y2="506.14"/>
                    <line fill="#FFFFFF" stroke="#000000" x1="129.92" y1="506.63" x2="130.411" y2="506.14"/>
                    <path fill="#FFFFFF" d="M95.243,538.831c-3.075-9.845,0.519-24.719,34.677-32.201"/>
                    <path id="linea910" fill="#FFFFFF" stroke="#FFFFFF" d="M95.243,538.831c-3.075-9.845,0.519-24.719,34.677-32.201"/>
                    <path id="linea2021" fill="#FFFFFF" stroke="#FFFFFF" d="M493.943,177.397c0,0-11.592,14.806,9.066,45.913
                        c3.673,5.529,7.737,11.243,11.947,17.287"/>
                    <path id="linea2122" fill="#FFFFFF" stroke="#FFFFFF" d="M394.886,262.343c11.474-34.673,39.898-67.736,99.059-84.946"/>
                    <path fill="#FFFFFF" stroke="#000000" d="M553.172,318.917c-15.393-38.572-54.849-50.44-54.849-50.44
                        c4.108,14.014-0.743,37.099-0.743,37.099c-2.262-12.264-16.464-22.522-16.464-22.522c-2.611,5.012-4.941,27.42-4.941,27.42
                        c-11.681-10.258-14.971-33.253-14.971-33.253c-22.994,21.084-16.207,87.38-16.207,87.38c-38.17-33.119-28.261-88.286-28.261-88.286
                        c-11.987,4.383-19.423,17.513-19.423,17.513c-4.48-6.995-2.428-31.482-2.428-31.482"/>
                    <path id="linea1920" fill="none" stroke="#FFFFFF" d="M514.956,240.598c14.234,20.441,30.135,44.664,38.216,78.319"/>
                    <path id="linea1718" fill="none" stroke="#FFFFFF" d="M544.413,416.51c-14.868,28.852-40.954,55.364-75.56,69.985"/>
                    <path id="linea1819" fill="none" stroke="#FFFFFF" d="M553.172,318.917c3.004,12.425,4.919,26.163,5.291,41.481
                        c0.45,17.973-4.473,37.527-14.05,56.111"/>
                    <path fill="none" stroke="#000000" d="M394.886,262.343c0,0-2.053,24.487,2.428,31.483
                        c0,0,7.436-13.129,19.424-17.512c0,0-9.91,55.167,28.26,88.285c0,0-6.787-66.296,16.207-87.38c0,0,3.29,22.995,14.971,33.253
                        c0,0,2.33-22.407,4.941-27.42c0,0,14.202,10.259,16.464,22.522c0,0,4.851-23.085,0.744-37.099c0,0,39.456,11.868,54.848,50.439"/>
                    <path id="linea2223" fill="none" stroke="#FFFFFF" d="M399.24,361.589c-9.567-23.27-16.65-62.163-4.354-99.245"/>
                    <path id="linea2324" fill="none" stroke="#FFFFFF" d="M424.774,430.332c2.18-16.163-3.934-32.358-18.836-55.521
                        c-2.229-3.467-4.526-7.943-6.698-13.222"/>
                    <path id="linea2425" fill="none" stroke="#FFFFFF" d="M371.59,479.324c0,0,32.904-3.058,45.938-27.844
                        c3.891-7.399,6.32-14.271,7.247-21.148"/>
                    <path id="linea1617" fill="none" stroke="#FFFFFF" d="M468.854,486.496c-28.623,12.094-63.076,16.051-101.833,6.467
                        c2.214-4.407,3.688-8.998,4.569-13.64"/>
                    <path id="linea1516" fill="none" stroke="#FFFFFF" d="M367.021,492.963c-2.212,4.434-5.176,8.651-9.047,12.547"/>
                    <path fill="none" stroke="#000000" d="M371.59,479.324c-0.883,4.641-2.354,9.23-4.568,13.639"/>
                    <path id="linea2526" fill="none" stroke="#FFFFFF" d="M364.831,432.08c4.729,10.213,10.26,29.031,6.76,47.243"/>
                    <path id="linea2627" fill="none" stroke="#FFFFFF" d="M346.046,388.256c7.314,17.272,14.208,33.96,18.785,43.823"/>
                    <path id="linea2728" fill="none" stroke="#FFFFFF" d="M323.046,337.676c6.521,12.146,15.013,31.714,23,50.58"/>
                    <path id="linea2829" fill="none" stroke="#FFFFFF" d="M306.327,297.676c4.111,13.062,9.278,26.694,15.434,37.655
                        c0.42,0.746,0.848,1.529,1.286,2.345"/>
                    <path id="linea2930" fill="none" stroke="#FFFFFF" d="M294.78,251.593c0,0,3.99,22.075,11.547,46.083"/>
                    <path fill="none" stroke="#000000" d="M357.974,505.51c-8.812,8.86-22.361,15.951-42.534,19.467
                        c-5.479-6.62-18.444-9.93-21.381-18.979c-3.984-12.239-2.612-32.132-2.612-32.132c13.734-16.392,26.697-41.297,23.947-60.418
                        c0,0,4.596,3.124,4.596,5.434c0.116-1.819,2.797-45.512-19.68-70.842c0,0,7.439,3.918,8.815,5.366c0,0,0.37-10.355-11.199-27.029
                        c-10.14-14.597-17.952-41.995-18.999-51.558c0.071-0.395,0.094-0.767,0.094-1.142v-0.022c5.316-7.905,10.561-15.224,15.76-22.061"
                        />
                    <path id="linea45" fill="none" stroke="#FFFFFF" d="M168.762,308.532c13.513-28.522,18.547-50.921,18.547-50.921"/>
                    <path id="linea56" fill="none" stroke="#FFFFFF" d="M143.243,355.157c5.042-9.543,9.799-17.679,13.376-23.723
                        c4.645-7.854,8.673-15.581,12.143-22.902"/>
                    <path id="linea67" fill="none" stroke="#FFFFFF" d="M121.842,404.756c5.805-18.152,13.916-35.43,21.401-49.6"/>
                    <path id="linea78" fill="none" stroke="#FFFFFF" d="M113.845,456.445c-0.055-2.708-0.051-5.513,0.009-8.416
                        c0.298-14.246,3.433-29.029,7.988-43.273"/>
                    <path id="linea89" fill="none" stroke="#FFFFFF" d="M130.411,506.14c-11.493-11.283-16.105-27.144-16.566-49.694"/>
                    <path fill="none" stroke="#000000" d="M187.309,257.611c8.556,3.383,17.534,7.162,26.955,11.357
                        c0,0-4.008,19.191-20.846,41.599c-7.718,10.262-18.609,38.662-19.799,51.325c0,0,7.764-5.48,11.215-5.876
                        c0,0-17.976,15.133-14.828,66.875c0,0,2.472-5.571,4.85-6.926c0,0-2.798,22.479,9.022,41.531c2.427,3.869,5.949,7.67,10.097,11.541
                        c0,0,3.542,33.372-3.637,39.34c-5.455,4.526-18.025,9.749-23.151,15.951c-16.56-4.223-28.453-10.005-36.775-18.189"/>
                    <path fill="#FFFFFF;" d="M267.031,262.905c-2.796-0.443-5.919-0.422-9.143,0.192c-5.06,0.927-9.393,3.098-12.378,5.871
                        c0,0-56.946-58.692-128.323-60.792c-70.021-2.027-96.839,35.376-97.819,36.795c-0.047,0.026-0.047,0.051-0.047,0.051
                        c-9.095-15.228,1.027-49.483,1.027-49.483c-6.972,2.986-14.692,13.477-14.692,13.477c3.662-56.921,50.814-94.812,50.814-94.812
                        s20.168-21.101,35.533-26.185c0,0-4.896-6.481-12.824-14.644c0,0,13.505-8.842,24.556-4.547
                        c7.788,6.877,11.053,11.498,11.053,11.498s40.644-18.611,83.012-22.997c52.581-5.453,71.283,1.544,71.283,1.544
                        s2.052-4.082,5.737-10.031l0.42,0.258c14.385-7.204,25.391-4.851,26.884-4.479c-3.285,5.623-6.504,11.893-9.487,18.913
                        c0,0,26.023,4.944,38.868,9.841c0,0,41.3,16.488,57.48,25.929c16.16,9.422,38.01,26.257,54.451,49.763
                        c0,0-12.452-4.641-21.196-5.436c0,0,11.212,10.403,15.738,18.634c0,0-0.047,0-0.117-0.022
                        c-3.265-0.934-73.453-20.176-113.91,14.479c-41.462,35.487-56.895,85.973-56.895,85.973L267.031,262.905z"/>
                    <path fill="#FFFFFF" stroke="#000000" d="M267.031,262.905c-2.796-0.443-5.919-0.422-9.143,0.192
                        c-5.06,0.927-9.393,3.098-12.378,5.871c0,0-56.946-58.692-128.323-60.792c-70.021-2.027-96.839,35.376-97.819,36.795
                        c-0.047,0.026-0.047,0.051-0.047,0.051c-9.095-15.228,1.027-49.483,1.027-49.483c-6.972,2.986-14.692,13.477-14.692,13.477
                        c3.662-56.921,50.814-94.812,50.814-94.812s20.168-21.101,35.533-26.185c0,0-4.896-6.481-12.824-14.644
                        c0,0,13.505-8.842,24.556-4.547c7.788,6.877,11.053,11.498,11.053,11.498s40.644-18.611,83.012-22.997
                        c52.581-5.453,71.283,1.544,71.283,1.544s2.052-4.082,5.737-10.031l0.42,0.258c14.385-7.204,25.391-4.851,26.884-4.479
                        c-3.285,5.623-6.504,11.893-9.487,18.913c0,0,26.023,4.944,38.868,9.841c0,0,41.3,16.488,57.48,25.929
                        c16.16,9.422,38.01,26.257,54.451,49.763c0,0-12.452-4.641-21.196-5.436c0,0,11.212,10.403,15.738,18.634c0,0-0.047,0-0.117-0.022
                        c-3.265-0.934-73.453-20.176-113.91,14.479c-41.462,35.487-56.895,85.973-56.895,85.973L267.031,262.905z"/>
                    <path fill="#231F20" d="M147.031,196.075c0,8.075-6.542,14.614-14.619,14.614c-8.069,0-14.613-6.539-14.613-14.614
                        c0-8.075,6.544-14.621,14.613-14.621C140.489,181.454,147.031,188,147.031,196.075"/>
                    <path fill="#FFFFFF" d="M141.398,190.824c0,2.06-1.67,3.727-3.732,3.727c-2.06,0-3.729-1.667-3.729-3.727
                        c0-2.063,1.67-3.732,3.729-3.732C139.729,187.092,141.398,188.761,141.398,190.824"/>
                    <path fill="#231F20" d="M318.452,177.4c0,8.072-6.546,14.616-14.614,14.616c-8.077,0-14.621-6.544-14.621-14.616
                        c0-8.072,6.544-14.621,14.621-14.621C311.906,162.779,318.452,169.328,318.452,177.4"/>
                    <path fill="#FFFFFF" d="M312.817,172.152c0,2.055-1.67,3.728-3.731,3.728c-2.061,0-3.729-1.673-3.729-3.728
                        c0-2.065,1.668-3.735,3.729-3.735C311.147,168.417,312.817,170.087,312.817,172.152"/>
                    <path onClick={valorPunto} id="1" className="punto" fill="#E30613" d="M22.204,244.975c0,1.565-1.269,2.834-2.835,2.834c-1.565,0-2.834-1.269-2.834-2.834
                        c0-1.566,1.269-2.835,2.834-2.835C20.935,242.14,22.204,243.409,22.204,244.975"/>
                    <path onClick={valorPunto} id="2" className="punto" fill="#E30613" d="M66.204,230.22c0,1.566-1.269,2.835-2.835,2.835c-1.565,0-2.834-1.269-2.834-2.835
                        c0-1.565,1.269-2.834,2.834-2.834C64.935,227.386,66.204,228.655,66.204,230.22"/>
                    <path onClick={valorPunto} id="3" className="punto" fill="#E30613" d="M128.204,237.47c0,1.566-1.269,2.835-2.835,2.835c-1.565,0-2.834-1.269-2.834-2.835
                        c0-1.565,1.269-2.834,2.834-2.834C126.935,234.636,128.204,235.905,128.204,237.47"/>
                    <path onClick={valorPunto} id="4" className="punto" fill="#E30613" d="M190.079,257.611c0,1.566-1.269,2.835-2.835,2.835c-1.565,0-2.834-1.269-2.834-2.835
                        c0-1.565,1.269-2.834,2.834-2.834C188.81,254.777,190.079,256.046,190.079,257.611"/>
                    <path onClick={valorPunto} id="5" className="punto" fill="#E30613" d="M171.412,308.444c0,1.565-1.269,2.835-2.834,2.835c-1.566,0-2.835-1.27-2.835-2.835
                        s1.269-2.834,2.835-2.834C170.143,305.61,171.412,306.879,171.412,308.444"/>
                    <path onClick={valorPunto} id="6" className="punto" fill="#E30613" d="M146.079,354.944c0,1.565-1.269,2.835-2.835,2.835c-1.565,0-2.834-1.27-2.834-2.835
                        s1.269-2.834,2.834-2.834C144.81,352.11,146.079,353.379,146.079,354.944"/>
                    <path onClick={valorPunto} id="7" className="punto" fill="#E30613" d="M124.745,404.778c0,1.564-1.269,2.835-2.834,2.835c-1.566,0-2.835-1.271-2.835-2.835
                        c0-1.565,1.269-2.835,2.835-2.835C123.476,401.943,124.745,403.213,124.745,404.778"/>
                    <path onClick={valorPunto} id="8" className="punto" fill="#E30613" d="M116.641,456.444c0,1.565-1.269,2.835-2.835,2.835c-1.565,0-2.834-1.27-2.834-2.835
                        s1.269-2.834,2.834-2.834C115.372,453.61,116.641,454.879,116.641,456.444"/>
                    <path onClick={valorPunto} id="9" className="punto" fill="#E30613" d="M132.808,506.14c0,1.564-1.269,2.835-2.834,2.835c-1.566,0-2.835-1.271-2.835-2.835
                        c0-1.565,1.269-2.835,2.835-2.835C131.539,503.305,132.808,504.574,132.808,506.14"/>
                    <path onClick={valorPunto} id="10" className="punto" fill="#E30613" d="M98.641,539.02c0,1.564-1.269,2.835-2.835,2.835c-1.565,0-2.834-1.271-2.834-2.835
                        c0-1.565,1.269-2.834,2.834-2.834C97.372,536.186,98.641,537.454,98.641,539.02"/>
                    <path onClick={valorPunto} id="11" className="punto" fill="#E30613" d="M176.641,541.067c0,1.564-1.269,2.835-2.835,2.835c-1.565,0-2.834-1.271-2.834-2.835
                        c0-1.565,1.269-2.834,2.834-2.834C175.372,538.233,176.641,539.502,176.641,541.067"/>
                    <path onClick={valorPunto} id="12" className="punto" fill="#E30613" d="M245.685,541.067c0,1.564-1.269,2.835-2.834,2.835c-1.566,0-2.835-1.271-2.835-2.835
                        c0-1.565,1.269-2.834,2.835-2.834C244.416,538.233,245.685,539.502,245.685,541.067"/>
                    <path onClick={valorPunto} id="13" className="punto"  fill="#E30613" d="M318.518,538.233c0,1.564-1.27,2.834-2.835,2.834c-1.564,0-2.834-1.27-2.834-2.834
                        c0-1.565,1.27-2.835,2.834-2.835C317.248,535.398,318.518,536.668,318.518,538.233"/>
                    <path onClick={valorPunto} id="14" className="punto"  fill="#E30613" d="M394.186,539.233c0,1.564-1.27,2.834-2.835,2.834c-1.564,0-2.834-1.27-2.834-2.834
                        c0-1.565,1.27-2.835,2.834-2.835C392.916,536.398,394.186,537.668,394.186,539.233"/>
                    <path onClick={valorPunto} id="15" className="punto" fill="#E30613;" d="M361.519,505.51c0,1.564-1.269,2.834-2.834,2.834s-2.835-1.27-2.835-2.834
                        c0-1.566,1.27-2.835,2.835-2.835S361.519,503.943,361.519,505.51"/>
                    <path onClick={valorPunto} id="16" className="punto"  fill="#E30613" d="M369.854,493.176c0,1.564-1.269,2.835-2.834,2.835s-2.835-1.271-2.835-2.835
                        c0-1.565,1.27-2.834,2.835-2.834S369.854,491.61,369.854,493.176"/>
                    <path onClick={valorPunto} id="17" className="punto"  fill="#E30613" d="M471.688,486.676c0,1.564-1.27,2.835-2.835,2.835c-1.564,0-2.835-1.271-2.835-2.835
                        c0-1.565,1.271-2.834,2.835-2.834C470.419,483.842,471.688,485.11,471.688,486.676"/>
                    <path onClick={valorPunto} id="18" className="punto"  fill="#E30613" d="M547.188,416.51c0,1.564-1.27,2.834-2.835,2.834c-1.564,0-2.835-1.27-2.835-2.834
                        c0-1.566,1.271-2.835,2.835-2.835C545.919,413.675,547.188,414.943,547.188,416.51"/>
                    <path onClick={valorPunto} id="24" className="punto"  fill="#E30613" d="M427.688,430.343c0,1.564-1.27,2.835-2.835,2.835c-1.564,0-2.835-1.271-2.835-2.835
                        c0-1.565,1.271-2.835,2.835-2.835C426.419,427.508,427.688,428.777,427.688,430.343"/>
                    <path onClick={valorPunto} id="23" className="punto"  fill="#E30613" d="M401.688,361.766c0,1.564-1.27,2.834-2.835,2.834c-1.564,0-2.835-1.27-2.835-2.834
                        c0-1.566,1.271-2.835,2.835-2.835C400.419,358.931,401.688,360.199,401.688,361.766"/>
                    <path onClick={valorPunto} id="19" className="punto"  fill="#E30613" d="M556.007,317.932c0,1.564-1.27,2.835-2.835,2.835c-1.564,0-2.835-1.271-2.835-2.835
                        c0-1.565,1.271-2.835,2.835-2.835C554.737,315.097,556.007,316.366,556.007,317.932"/>
                    <path onClick={valorPunto} id="22" className="punto"  fill="#E30613" d="M397.251,263.098c0,1.565-1.27,2.834-2.835,2.834c-1.564,0-2.834-1.269-2.834-2.834
                        c0-1.566,1.27-2.835,2.834-2.835C395.981,260.263,397.251,261.532,397.251,263.098"/>
                    <path onClick={valorPunto} id="20" className="punto"  fill="#E30613" d="M518.251,240.598c0,1.565-1.27,2.834-2.835,2.834c-1.564,0-2.834-1.269-2.834-2.834
                        c0-1.566,1.27-2.835,2.834-2.835C516.981,237.763,518.251,239.032,518.251,240.598"/>
                    <path onClick={valorPunto} id="25" className="punto"  fill="#E30613" d="M374.354,479.01c0,1.564-1.269,2.834-2.834,2.834s-2.835-1.27-2.835-2.834
                        c0-1.566,1.27-2.835,2.835-2.835S374.354,477.443,374.354,479.01"/>
                    <path onClick={valorPunto} id="26" className="punto"  fill="#E30613" d="M367.853,433.178c0,1.564-1.27,2.834-2.835,2.834c-1.564,0-2.835-1.27-2.835-2.834
                        c0-1.566,1.271-2.835,2.835-2.835C366.583,430.343,367.853,431.611,367.853,433.178"/>
                    <path onClick={valorPunto} id="28" className="punto"  fill="#E30613" d="M325.811,337.676c0,1.564-1.27,2.835-2.835,2.835c-1.564,0-2.835-1.271-2.835-2.835
                        c0-1.565,1.271-2.834,2.835-2.834C324.541,334.842,325.811,336.11,325.811,337.676"/>
                    <path onClick={valorPunto} id="27" className="punto"  fill="#E30613" d="M348.811,388.285c0,1.564-1.27,2.835-2.835,2.835c-1.564,0-2.835-1.271-2.835-2.835
                        c0-1.565,1.271-2.835,2.835-2.835C347.541,385.45,348.811,386.72,348.811,388.285"/>
                    <path onClick={valorPunto} id="29" className="punto"  fill="#E30613" d="M309.353,297.676c0,1.564-1.27,2.835-2.835,2.835c-1.564,0-2.835-1.271-2.835-2.835
                        c0-1.565,1.271-2.834,2.835-2.834C308.083,294.842,309.353,296.11,309.353,297.676"/>
                    <path onClick={valorPunto} id="30" className="punto"  fill="#E30613" d="M297.853,251.593c0,1.566-1.27,2.835-2.835,2.835c-1.564,0-2.835-1.269-2.835-2.835
                        c0-1.565,1.271-2.834,2.835-2.834C296.583,248.759,297.853,250.028,297.853,251.593"/>
                    <path onClick={valorPunto} id="21" className="punto"  fill="#E30613" d="M497.872,177.397c0,2-1.622,3.621-3.622,3.621s-3.621-1.621-3.621-3.621s1.621-3.622,3.621-3.622
                        S497.872,175.397,497.872,177.397"/>
                    <g>
                        <path d="M19.163,252.476h-0.02l-1.129,0.609l-0.17-0.669l1.419-0.76h0.75v6.497h-0.849V252.476z"/>
                    </g>
                    <g>
                        <path d="M61.253,241.733v-0.54l0.689-0.67c1.659-1.579,2.409-2.418,2.419-3.398c0-0.66-0.32-1.27-1.29-1.27
                            c-0.589,0-1.079,0.3-1.379,0.55l-0.28-0.62c0.45-0.38,1.089-0.66,1.839-0.66c1.399,0,1.989,0.959,1.989,1.889
                            c0,1.199-0.87,2.168-2.239,3.488l-0.52,0.48v0.02h2.918v0.729H61.253z"/>
                    </g>
                    <g>
                        <path d="M123.463,248.974c0.25,0.16,0.83,0.41,1.439,0.41c1.129,0,1.479-0.72,1.469-1.259c-0.01-0.91-0.83-1.299-1.679-1.299
                            h-0.49v-0.66h0.49c0.64,0,1.449-0.33,1.449-1.1c0-0.52-0.33-0.979-1.14-0.979c-0.52,0-1.02,0.23-1.299,0.43l-0.23-0.64
                            c0.34-0.25,1-0.5,1.699-0.5c1.279,0,1.859,0.76,1.859,1.549c0,0.669-0.399,1.239-1.199,1.529v0.02
                            c0.8,0.16,1.449,0.76,1.449,1.669c0,1.04-0.81,1.949-2.369,1.949c-0.729,0-1.369-0.23-1.689-0.439L123.463,248.974z"/>
                    </g>
                    <g>
                        <path d="M179.392,263.823v-1.769h-3.019v-0.58l2.898-4.148h0.95v4.038h0.909v0.689h-0.909v1.769H179.392z M179.392,261.364v-2.169
                            c0-0.34,0.01-0.68,0.03-1.02h-0.03c-0.2,0.38-0.36,0.66-0.54,0.959l-1.589,2.209v0.02H179.392z"/>
                    </g>
                    <g>
                        <path d="M163.051,305.176h-2.479l-0.25,1.669c0.15-0.02,0.29-0.04,0.53-0.04c0.5,0,1,0.11,1.399,0.35
                            c0.51,0.29,0.93,0.85,0.93,1.67c0,1.269-1.009,2.219-2.418,2.219c-0.71,0-1.31-0.2-1.619-0.4l0.22-0.67
                            c0.27,0.16,0.8,0.36,1.389,0.36c0.83,0,1.539-0.54,1.539-1.409c-0.01-0.84-0.569-1.439-1.869-1.439c-0.37,0-0.66,0.04-0.899,0.07
                            l0.419-3.118h3.108V305.176z"/>
                    </g>
                    <g>
                        <path d="M138.202,349.605c-0.18-0.01-0.41,0-0.66,0.04c-1.379,0.23-2.109,1.239-2.259,2.309h0.03
                            c0.31-0.409,0.849-0.749,1.569-0.749c1.149,0,1.959,0.829,1.959,2.099c0,1.189-0.81,2.289-2.159,2.289
                            c-1.389,0-2.299-1.08-2.299-2.769c0-1.279,0.46-2.289,1.1-2.929c0.54-0.529,1.259-0.859,2.079-0.96
                            c0.26-0.039,0.48-0.05,0.64-0.05V349.605z M137.952,353.354c0-0.93-0.53-1.489-1.339-1.489c-0.529,0-1.019,0.33-1.259,0.8
                            c-0.06,0.1-0.1,0.229-0.1,0.39c0.02,1.069,0.51,1.859,1.429,1.859C137.442,354.913,137.952,354.283,137.952,353.354z"/>
                    </g>
                    <g>
                        <path d="M115.911,399.677v0.579l-2.829,5.917h-0.909l2.818-5.747v-0.02h-3.178v-0.729H115.911z"/>
                    </g>
                    <g>
                        <path d="M105.164,457.283c0-0.819,0.49-1.398,1.29-1.738l-0.01-0.03c-0.719-0.34-1.029-0.899-1.029-1.459
                            c0-1.03,0.87-1.729,2.009-1.729c1.259,0,1.889,0.79,1.889,1.6c0,0.549-0.27,1.139-1.069,1.519v0.03
                            c0.81,0.319,1.309,0.89,1.309,1.679c0,1.13-0.969,1.89-2.208,1.89C105.983,459.043,105.164,458.233,105.164,457.283z
                            M108.662,457.243c0-0.789-0.549-1.169-1.429-1.419c-0.759,0.22-1.169,0.72-1.169,1.34c-0.03,0.659,0.47,1.239,1.299,1.239
                            C108.152,458.403,108.662,457.913,108.662,457.243z M106.243,453.995c0,0.649,0.49,1,1.239,1.199
                            c0.56-0.189,0.989-0.589,0.989-1.179c0-0.52-0.31-1.06-1.099-1.06C106.643,452.956,106.243,453.436,106.243,453.995z"/>
                    </g>
                    <g>
                        <path d="M119.923,506.063c0.19,0.021,0.41,0,0.709-0.029c0.51-0.07,0.99-0.28,1.359-0.63c0.43-0.39,0.74-0.96,0.859-1.729h-0.03
                            c-0.36,0.44-0.879,0.7-1.529,0.7c-1.169,0-1.919-0.88-1.919-1.989c0-1.229,0.89-2.309,2.219-2.309c1.33,0,2.149,1.079,2.149,2.738
                            c0,1.43-0.479,2.429-1.119,3.049c-0.5,0.489-1.189,0.789-1.889,0.869c-0.32,0.05-0.6,0.061-0.81,0.05V506.063z M120.243,502.325
                            c0,0.81,0.49,1.379,1.25,1.379c0.589,0,1.049-0.29,1.279-0.68c0.05-0.08,0.08-0.18,0.08-0.319c0-1.109-0.41-1.959-1.329-1.959
                            C120.773,500.746,120.243,501.405,120.243,502.325z"/>
                    </g>
                    <g>
                        <path d="M84.593,540.596h-0.02l-1.129,0.609l-0.17-0.67l1.419-0.759h0.75v6.496h-0.849V540.596z"/>
                        <path d="M92.131,542.954c0,2.209-0.82,3.429-2.259,3.429c-1.27,0-2.129-1.189-2.149-3.339c0-2.179,0.939-3.378,2.259-3.378
                            C91.352,539.666,92.131,540.886,92.131,542.954z M88.603,543.055c0,1.688,0.52,2.648,1.319,2.648c0.899,0,1.329-1.05,1.329-2.709
                            c0-1.599-0.41-2.648-1.319-2.648C89.163,540.346,88.603,541.285,88.603,543.055z"/>
                    </g>
                    <g>
                        <path d="M170.343,546.716h-0.02l-1.129,0.609l-0.17-0.67l1.419-0.759h0.75v6.496h-0.849V546.716z"/>
                        <path d="M175.473,546.716h-0.02l-1.129,0.609l-0.17-0.67l1.419-0.759h0.75v6.496h-0.849V546.716z"/>
                    </g>
                    <g>
                        <path d="M238.462,546.716h-0.02l-1.129,0.609l-0.17-0.67l1.419-0.759h0.75v6.496h-0.849V546.716z"/>
                        <path d="M241.683,552.393v-0.54l0.689-0.669c1.659-1.579,2.409-2.419,2.419-3.398c0-0.66-0.32-1.27-1.29-1.27
                            c-0.589,0-1.079,0.3-1.379,0.55l-0.28-0.619c0.45-0.38,1.089-0.66,1.839-0.66c1.399,0,1.989,0.96,1.989,1.889
                            c0,1.2-0.87,2.169-2.239,3.488l-0.52,0.479v0.021h2.918v0.729H241.683z"/>
                    </g>
                    <g>
                        <path d="M313.962,542.996h-0.02l-1.13,0.609l-0.17-0.67l1.42-0.759h0.749v6.496h-0.85V542.996z"/>
                        <path d="M317.393,547.663c0.25,0.16,0.83,0.41,1.439,0.41c1.13,0,1.479-0.72,1.47-1.26c-0.011-0.909-0.83-1.299-1.68-1.299h-0.489
                            v-0.66h0.489c0.64,0,1.449-0.329,1.449-1.099c0-0.52-0.329-0.979-1.139-0.979c-0.521,0-1.02,0.229-1.3,0.43l-0.229-0.64
                            c0.34-0.25,0.999-0.5,1.699-0.5c1.279,0,1.858,0.76,1.858,1.549c0,0.67-0.399,1.239-1.199,1.529v0.021
                            c0.8,0.159,1.449,0.76,1.449,1.669c0,1.039-0.81,1.949-2.369,1.949c-0.729,0-1.369-0.23-1.688-0.44L317.393,547.663z"/>
                    </g>
                    <g>
                        <path d="M389.642,544.376h-0.02l-1.13,0.609l-0.17-0.67l1.42-0.759h0.749v6.496h-0.85V544.376z"/>
                        <path d="M395.581,550.053v-1.769h-3.018v-0.58l2.897-4.147h0.95v4.037h0.909v0.69h-0.909v1.769H395.581z M395.581,547.594v-2.169
                            c0-0.339,0.011-0.679,0.03-1.019h-0.03c-0.199,0.379-0.359,0.659-0.539,0.959l-1.59,2.209v0.02H395.581z"/>
                    </g>
                    <g>
                        <path d="M345.892,500.386h-0.02l-1.13,0.609l-0.17-0.67l1.42-0.759h0.749v6.496h-0.85V500.386z"/>
                        <path d="M352.99,500.306h-2.479l-0.25,1.669c0.15-0.02,0.29-0.04,0.53-0.04c0.5,0,0.999,0.11,1.399,0.35
                            c0.51,0.29,0.93,0.85,0.93,1.67c0,1.269-1.01,2.219-2.419,2.219c-0.71,0-1.31-0.2-1.619-0.4l0.22-0.669
                            c0.27,0.159,0.8,0.359,1.39,0.359c0.829,0,1.539-0.54,1.539-1.409c-0.011-0.84-0.57-1.439-1.869-1.439
                            c-0.37,0-0.66,0.04-0.899,0.07l0.42-3.118h3.107V500.306z"/>
                    </g>
                    <g>
                        <path d="M354.512,488.756h-0.02l-1.13,0.609l-0.17-0.67l1.42-0.759h0.749v6.496h-0.85V488.756z"/>
                        <path d="M361.441,488.556c-0.181-0.01-0.41,0-0.66,0.04c-1.379,0.23-2.108,1.239-2.259,2.309h0.03
                            c0.31-0.409,0.85-0.749,1.569-0.749c1.149,0,1.958,0.829,1.958,2.099c0,1.189-0.809,2.289-2.158,2.289
                            c-1.39,0-2.299-1.08-2.299-2.769c0-1.279,0.46-2.289,1.1-2.929c0.539-0.529,1.259-0.859,2.078-0.96
                            c0.261-0.039,0.48-0.05,0.641-0.05V488.556z M361.191,492.304c0-0.93-0.53-1.489-1.34-1.489c-0.529,0-1.02,0.33-1.259,0.8
                            c-0.061,0.1-0.101,0.229-0.101,0.39c0.021,1.069,0.51,1.859,1.43,1.859C360.682,493.863,361.191,493.233,361.191,492.304z"/>
                    </g>
                    <g>
                        <path d="M467.262,491.937h-0.02l-1.13,0.609l-0.17-0.67l1.42-0.759h0.749v6.496h-0.85V491.937z"/>
                        <path d="M474.7,491.117v0.579l-2.828,5.917h-0.909l2.818-5.747v-0.02h-3.179v-0.729H474.7z"/>
                    </g>
                    <g>
                        <path d="M548.932,420.776h-0.02l-1.13,0.609l-0.17-0.67l1.42-0.759h0.749v6.496h-0.85V420.776z"/>
                        <path d="M552.073,424.804c0-0.819,0.489-1.398,1.289-1.738l-0.01-0.03c-0.72-0.34-1.029-0.899-1.029-1.459
                            c0-1.03,0.869-1.729,2.009-1.729c1.259,0,1.889,0.79,1.889,1.6c0,0.549-0.27,1.139-1.069,1.519v0.03
                            c0.81,0.319,1.31,0.89,1.31,1.679c0,1.13-0.97,1.89-2.209,1.89C552.893,426.563,552.073,425.754,552.073,424.804z
                            M555.571,424.764c0-0.789-0.55-1.169-1.43-1.419c-0.76,0.22-1.169,0.72-1.169,1.34c-0.03,0.659,0.47,1.239,1.299,1.239
                            C555.062,425.924,555.571,425.434,555.571,424.764z M553.152,421.516c0,0.649,0.489,1,1.239,1.199
                            c0.56-0.189,0.989-0.589,0.989-1.179c0-0.521-0.31-1.06-1.1-1.06C553.552,420.477,553.152,420.956,553.152,421.516z"/>
                    </g>
                    <g>
                        <path d="M558.853,314.756h-0.02l-1.13,0.609l-0.17-0.67l1.42-0.759h0.749v6.496h-0.85V314.756z"/>
                        <path d="M562.584,319.813c0.189,0.021,0.409,0,0.709-0.029c0.51-0.07,0.989-0.28,1.359-0.63c0.43-0.39,0.739-0.96,0.859-1.729
                            h-0.03c-0.359,0.44-0.879,0.7-1.528,0.7c-1.17,0-1.919-0.88-1.919-1.989c0-1.229,0.889-2.309,2.219-2.309
                            c1.329,0,2.148,1.079,2.148,2.738c0,1.43-0.479,2.429-1.119,3.049c-0.5,0.489-1.189,0.789-1.89,0.869
                            c-0.319,0.05-0.6,0.061-0.809,0.05V319.813z M562.903,316.075c0,0.81,0.489,1.379,1.249,1.379c0.59,0,1.05-0.29,1.279-0.68
                            c0.05-0.08,0.08-0.18,0.08-0.319c0-1.109-0.409-1.959-1.329-1.959C563.433,314.496,562.903,315.155,562.903,316.075z"/>
                    </g>
                    <g>
                        <path d="M519.773,241.153v-0.54l0.689-0.67c1.659-1.579,2.408-2.418,2.418-3.398c0-0.66-0.319-1.27-1.289-1.27
                            c-0.59,0-1.079,0.3-1.379,0.55l-0.28-0.62c0.45-0.38,1.09-0.66,1.839-0.66c1.399,0,1.989,0.959,1.989,1.889
                            c0,1.199-0.869,2.168-2.238,3.488l-0.521,0.48v0.02h2.919v0.729H519.773z"/>
                        <path d="M529.221,237.834c0,2.209-0.819,3.428-2.259,3.428c-1.27,0-2.129-1.189-2.149-3.338c0-2.179,0.939-3.378,2.259-3.378
                            C528.441,234.546,529.221,235.766,529.221,237.834z M525.692,237.935c0,1.689,0.52,2.648,1.319,2.648
                            c0.899,0,1.329-1.049,1.329-2.708c0-1.599-0.409-2.648-1.319-2.648C526.252,235.226,525.692,236.166,525.692,237.935z"/>
                    </g>
                    <g>
                        <path d="M500.604,178.483v-0.54l0.689-0.67c1.659-1.579,2.408-2.418,2.418-3.398c0-0.66-0.319-1.27-1.289-1.27
                            c-0.59,0-1.079,0.3-1.379,0.55l-0.28-0.62c0.45-0.38,1.09-0.66,1.839-0.66c1.399,0,1.989,0.959,1.989,1.889
                            c0,1.199-0.869,2.168-2.238,3.488l-0.521,0.48v0.02h2.919v0.729H500.604z"/>
                        <path d="M507.642,172.806h-0.02l-1.13,0.609l-0.17-0.669l1.42-0.76h0.749v6.497h-0.85V172.806z"/>
                    </g>
                    <g>
                        <path d="M379.733,265.733v-0.54l0.689-0.67c1.659-1.579,2.408-2.418,2.418-3.398c0-0.66-0.319-1.27-1.289-1.27
                            c-0.59,0-1.079,0.3-1.379,0.55l-0.28-0.62c0.45-0.38,1.09-0.66,1.839-0.66c1.399,0,1.989,0.959,1.989,1.889
                            c0,1.199-0.869,2.168-2.238,3.488l-0.521,0.48v0.02h2.919v0.729H379.733z"/>
                        <path d="M384.863,265.733v-0.54l0.689-0.67c1.659-1.579,2.408-2.418,2.418-3.398c0-0.66-0.319-1.27-1.289-1.27
                            c-0.59,0-1.079,0.3-1.379,0.55l-0.28-0.62c0.45-0.38,1.09-0.66,1.839-0.66c1.399,0,1.989,0.959,1.989,1.889
                            c0,1.199-0.869,2.168-2.238,3.488l-0.521,0.48v0.02h2.919v0.729H384.863z"/>
                    </g>
                    <g>
                        <path d="M385.354,365.152v-0.54l0.689-0.669c1.659-1.579,2.408-2.419,2.418-3.398c0-0.66-0.319-1.27-1.289-1.27
                            c-0.59,0-1.079,0.3-1.379,0.55l-0.28-0.619c0.45-0.38,1.09-0.66,1.839-0.66c1.399,0,1.989,0.96,1.989,1.889
                            c0,1.2-0.869,2.169-2.238,3.488l-0.521,0.479v0.021h2.919v0.729H385.354z"/>
                        <path d="M390.692,364.143c0.25,0.16,0.83,0.41,1.439,0.41c1.13,0,1.479-0.72,1.47-1.26c-0.011-0.909-0.83-1.299-1.68-1.299h-0.489
                            v-0.66h0.489c0.64,0,1.449-0.329,1.449-1.099c0-0.521-0.329-0.979-1.139-0.979c-0.521,0-1.02,0.229-1.3,0.43l-0.229-0.64
                            c0.34-0.25,0.999-0.5,1.699-0.5c1.279,0,1.858,0.76,1.858,1.549c0,0.67-0.399,1.239-1.199,1.529v0.021
                            c0.8,0.159,1.449,0.76,1.449,1.669c0,1.039-0.81,1.949-2.369,1.949c-0.729,0-1.369-0.23-1.688-0.44L390.692,364.143z"/>
                    </g>
                    <g>
                        <path d="M411.714,432.843v-0.54l0.689-0.669c1.659-1.579,2.408-2.419,2.418-3.398c0-0.66-0.319-1.27-1.289-1.27
                            c-0.59,0-1.079,0.3-1.379,0.55l-0.28-0.619c0.45-0.38,1.09-0.66,1.839-0.66c1.399,0,1.989,0.96,1.989,1.889
                            c0,1.199-0.869,2.169-2.238,3.488l-0.521,0.479v0.021h2.919v0.729H411.714z"/>
                        <path d="M419.562,432.843v-1.769h-3.018v-0.58l2.897-4.147h0.95v4.037h0.909v0.69h-0.909v1.769H419.562z M419.562,430.384v-2.169
                            c0-0.339,0.011-0.679,0.03-1.019h-0.03c-0.199,0.379-0.359,0.659-0.539,0.959l-1.59,2.209v0.02H419.562z"/>
                    </g>
                    <g>
                        <path d="M369.674,433.732v-0.54l0.689-0.669c1.659-1.579,2.408-2.419,2.418-3.398c0-0.66-0.319-1.27-1.289-1.27
                            c-0.59,0-1.079,0.3-1.379,0.55l-0.28-0.619c0.45-0.38,1.09-0.66,1.839-0.66c1.399,0,1.989,0.96,1.989,1.889
                            c0,1.2-0.869,2.169-2.238,3.488l-0.521,0.479v0.021h2.919v0.729H369.674z"/>
                        <path d="M378.512,427.855c-0.181-0.01-0.41,0-0.66,0.04c-1.379,0.23-2.108,1.239-2.259,2.309h0.03
                            c0.31-0.409,0.85-0.749,1.569-0.749c1.149,0,1.958,0.829,1.958,2.099c0,1.189-0.809,2.289-2.158,2.289
                            c-1.39,0-2.299-1.08-2.299-2.769c0-1.279,0.46-2.289,1.1-2.929c0.539-0.529,1.259-0.859,2.078-0.96
                            c0.261-0.039,0.48-0.05,0.641-0.05V427.855z M378.262,431.604c0-0.93-0.53-1.489-1.34-1.489c-0.529,0-1.02,0.33-1.259,0.8
                            c-0.061,0.1-0.101,0.229-0.101,0.39c0.021,1.069,0.51,1.859,1.43,1.859C377.752,433.163,378.262,432.533,378.262,431.604z"/>
                    </g>
                    <g>
                        <path d="M350.504,390.063v-0.54l0.689-0.669c1.659-1.579,2.408-2.419,2.418-3.398c0-0.66-0.319-1.27-1.289-1.27
                            c-0.59,0-1.079,0.3-1.379,0.55l-0.28-0.619c0.45-0.38,1.09-0.66,1.839-0.66c1.399,0,1.989,0.96,1.989,1.889
                            c0,1.2-0.869,2.169-2.238,3.488l-0.521,0.479v0.021h2.919v0.729H350.504z"/>
                        <path d="M359.851,383.566v0.579l-2.828,5.917h-0.909l2.818-5.747v-0.02h-3.179v-0.729H359.851z"/>
                    </g>
                    <g>
                        <path d="M327.504,338.232v-0.54l0.689-0.669c1.659-1.579,2.408-2.419,2.418-3.398c0-0.66-0.319-1.27-1.289-1.27
                            c-0.59,0-1.079,0.3-1.379,0.55l-0.28-0.619c0.45-0.38,1.09-0.66,1.839-0.66c1.399,0,1.989,0.96,1.989,1.889
                            c0,1.2-0.869,2.169-2.238,3.488l-0.521,0.479v0.021h2.919v0.729H327.504z"/>
                        <path d="M332.554,336.583c0-0.819,0.489-1.398,1.289-1.738l-0.01-0.03c-0.72-0.34-1.029-0.899-1.029-1.459
                            c0-1.03,0.869-1.729,2.009-1.729c1.259,0,1.889,0.79,1.889,1.6c0,0.549-0.27,1.139-1.069,1.519v0.03
                            c0.81,0.319,1.31,0.89,1.31,1.679c0,1.13-0.97,1.89-2.209,1.89C333.373,338.343,332.554,337.533,332.554,336.583z
                            M336.052,336.543c0-0.789-0.55-1.169-1.43-1.419c-0.76,0.22-1.169,0.72-1.169,1.34c-0.03,0.659,0.47,1.239,1.299,1.239
                            C335.542,337.703,336.052,337.213,336.052,336.543z M333.633,333.295c0,0.649,0.489,1,1.239,1.199
                            c0.56-0.189,0.989-0.589,0.989-1.179c0-0.521-0.31-1.06-1.1-1.06C334.032,332.256,333.633,332.735,333.633,333.295z"/>
                    </g>
                    <g>
                        <path d="M311.334,300.183v-0.54l0.689-0.669c1.659-1.579,2.408-2.419,2.418-3.398c0-0.66-0.319-1.27-1.289-1.27
                            c-0.59,0-1.079,0.3-1.379,0.55l-0.28-0.619c0.45-0.38,1.09-0.66,1.839-0.66c1.399,0,1.989,0.96,1.989,1.889
                            c0,1.199-0.869,2.169-2.238,3.488l-0.521,0.479v0.021h2.919v0.729H311.334z"/>
                        <path d="M316.973,299.563c0.19,0.021,0.41,0,0.71-0.029c0.51-0.07,0.989-0.28,1.359-0.63c0.43-0.39,0.739-0.96,0.859-1.729h-0.03
                            c-0.359,0.44-0.879,0.7-1.528,0.7c-1.17,0-1.919-0.88-1.919-1.989c0-1.229,0.889-2.309,2.219-2.309
                            c1.329,0,2.148,1.079,2.148,2.738c0,1.43-0.479,2.429-1.119,3.049c-0.5,0.489-1.189,0.789-1.89,0.869
                            c-0.319,0.05-0.6,0.061-0.81,0.05V299.563z M317.293,295.825c0,0.81,0.489,1.379,1.249,1.379c0.59,0,1.05-0.29,1.279-0.68
                            c0.05-0.08,0.08-0.18,0.08-0.319c0-1.109-0.409-1.959-1.329-1.959C317.822,294.246,317.293,294.905,317.293,295.825z"/>
                    </g>
                    <g>
                        <path d="M300.303,254.874c0.25,0.16,0.83,0.41,1.439,0.41c1.13,0,1.479-0.72,1.47-1.259c-0.011-0.91-0.83-1.299-1.68-1.299h-0.489
                            v-0.66h0.489c0.64,0,1.449-0.33,1.449-1.1c0-0.52-0.329-0.979-1.139-0.979c-0.521,0-1.02,0.23-1.3,0.43l-0.229-0.64
                            c0.34-0.25,0.999-0.5,1.699-0.5c1.279,0,1.858,0.76,1.858,1.549c0,0.669-0.399,1.239-1.199,1.529v0.02
                            c0.8,0.16,1.449,0.76,1.449,1.669c0,1.039-0.81,1.949-2.369,1.949c-0.729,0-1.369-0.23-1.688-0.439L300.303,254.874z"/>
                        <path d="M309.541,252.564c0,2.209-0.819,3.428-2.259,3.428c-1.27,0-2.129-1.189-2.149-3.338c0-2.179,0.939-3.378,2.259-3.378
                            C308.762,249.276,309.541,250.496,309.541,252.564z M306.013,252.665c0,1.689,0.52,2.648,1.319,2.648
                            c0.899,0,1.329-1.049,1.329-2.708c0-1.599-0.409-2.648-1.319-2.648C306.572,249.956,306.013,250.896,306.013,252.665z"/>
                    </g>
                    <g>
                        <path d="M356.834,480.993v-0.54l0.689-0.669c1.659-1.579,2.408-2.419,2.418-3.398c0-0.66-0.319-1.27-1.289-1.27
                            c-0.59,0-1.079,0.3-1.379,0.55l-0.28-0.619c0.45-0.38,1.09-0.66,1.839-0.66c1.399,0,1.989,0.96,1.989,1.889
                            c0,1.199-0.869,2.169-2.238,3.488l-0.521,0.479v0.021h2.919v0.729H356.834z"/>
                        <path d="M365.841,475.236h-2.479l-0.249,1.669c0.149-0.02,0.289-0.04,0.529-0.04c0.5,0,0.999,0.11,1.399,0.35
                            c0.51,0.29,0.93,0.85,0.93,1.67c0,1.269-1.01,2.219-2.419,2.219c-0.71,0-1.31-0.2-1.619-0.4l0.22-0.67
                            c0.27,0.16,0.8,0.36,1.39,0.36c0.829,0,1.539-0.54,1.539-1.409c-0.011-0.84-0.57-1.439-1.869-1.439c-0.37,0-0.66,0.04-0.899,0.07
                            l0.42-3.118h3.107V475.236z"/>
                    </g>
                </g>            
                </svg>  : 
                <svg    
                version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" 
                x="0px" y="0px"
                width="820.35px" height="640.35px" viewBox="0 0 820.35 640.35" 
                onClick={obtenetID}
            >
                <g>
                <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M435.98,399.86c-1.38-1.45-8.81-5.36-8.81-5.36
                    c22.47,25.32,19.79,69,19.67,70.82c0-2.31-4.59-5.43-4.59-5.43c2.75,19.12-10.21,44.01-23.94,60.4
                    c-4.68,5.62-9.46,10.23-13.73,13.27c-16.69,11.87-35.62,42.03-35.62,42.03c-6.15-28.56-31.89-45.01-48.09-60.12
                    c-4.15-3.87-7.67-7.67-10.09-11.54c-11.82-19.05-9.02-41.52-9.02-41.52c-2.38,1.35-4.85,6.92-4.85,6.92
                    c-3.15-51.73,14.82-66.86,14.82-66.86c-3.45,0.4-11.21,5.88-11.21,5.88c1.19-12.66,12.08-41.05,19.79-51.31
                    c16.83-22.4,20.84-41.59,20.84-41.59c9.16,4.08,18.74,8.55,28.74,13.47h0.03c3.33,4.5,11.05,6.79,19.4,5.24
                    c8.86-1.66,15.55-7.08,16.48-12.87c1.05,9.56,8.85,36.95,18.99,51.54C436.35,389.51,435.98,399.86,435.98,399.86z"/>
            </g>
            <g>
                <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M384.76,309.58c3.22-0.61,6.34-0.64,9.14-0.19
                    c6.25,1,11,4.36,11.87,9.02c-0.14,0.56-0.12,1.54,0.02,2.89c-0.94,5.78-7.63,11.21-16.48,12.87c-8.35,1.54-16.06-0.74-19.4-5.24
                    c-0.82-1.07-1.35-2.29-1.61-3.58c-0.65-3.48,0.98-7.02,4.08-9.89C375.37,312.68,379.7,310.51,384.76,309.58z"/>
            </g>
            <g>
                <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M405.77,318.41c-0.87-4.66-5.62-8.02-11.87-9.02l0.02-0.21
                    c0,0,15.43-50.47,56.88-85.95c40.44-34.64,110.61-15.41,113.87-14.47c-3.17-0.56-67.2-10.28-143.04,89.32
                    c-5.2,6.83-10.44,14.15-15.75,22.06C405.9,319.58,405.88,318.99,405.77,318.41z"/>
            </g>
            <g>
                <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M369.88,328.92c-10-4.92-19.58-9.39-28.74-13.47
                    c-9.42-4.19-18.39-7.97-26.95-11.35c-131.82-52.19-166.63-14.08-167.89-12.64c0.98-1.42,27.79-38.81,97.79-36.78
                    c71.35,2.1,128.28,60.77,128.28,60.77c-3.1,2.87-4.73,6.41-4.08,9.89c0.26,1.3,0.79,2.52,1.61,3.58H369.88z"/>
            </g>
            <g>
                <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M183.4,160.73c-23.15-32.4-31.78-69.54-29.84-72.61
                    c20.26,2.29,40.14,19.07,52.54,31.8c7.93,8.16,12.82,14.64,12.82,14.64C203.56,139.64,183.4,160.73,183.4,160.73z"/>
            </g>
            <g>
                <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M428.98,91.17c16.44-28.04,34.64-39.35,34.64-39.35
                    s4.5,29.61-5.27,68.09c-12.84-4.9-38.86-9.84-38.86-9.84C422.48,103.06,425.69,96.79,428.98,91.17z"/>
            </g>
            <g>
                <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M230.65,115.37c-11.05-4.29-24.55,4.55-24.55,4.55
                    c-12.4-12.73-32.28-29.51-52.54-31.8c1.96-3.1,22.1-8.32,49.95,7.91C215.73,103.13,224.59,109.96,230.65,115.37z"/>
            </g>
            <g>
                <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M401.68,95.39c10.42-16.73,34.01-48.16,61.93-43.57
                    c0,0-18.2,11.3-34.64,39.35c-1.49-0.37-12.49-2.72-26.87,4.48L401.68,95.39z"/>
            </g>
            <g>
                <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M484.81,551.92c33.8,8.88,36.78,23.89,33.31,33.62
                    c-2.5,6.92-76.21-1.57-76.21-1.57c1.4-1.05,2.33-2.38,2.64-4.03c0.67-3.52-0.35-6.25-2.27-8.56
                    C462.46,567.87,476,560.78,484.81,551.92z"/>
            </g>
            <g>
                <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M442.29,571.38c1.91,2.31,2.94,5.04,2.27,8.56
                    c-0.3,1.65-1.24,2.98-2.64,4.03c-6.81,5.2-24.41,4.03-31.45,4.66c-8.46,0.77-41.38-0.84-41.38-0.84
                    c1.49-0.44-0.14-12.22-0.14-12.22s18.93-30.16,35.62-42.03c4.27-3.03,9.04-7.65,13.73-13.27c0,0-1.37,19.89,2.61,32.12
                    C423.85,561.45,436.81,564.76,442.29,571.38z"/>
            </g>
            <g>
                <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M294.08,570.73c5.13-6.2,17.69-11.42,23.14-15.95
                    c7.18-5.96,3.64-39.33,3.64-39.33c16.2,15.11,41.94,31.56,48.09,60.12c0,0,1.63,11.77,0.14,12.22c-1.46,0.42-30.93,0.3-48.48,1.35
                    c-7.69,0.44-13.92-0.14-18.58-1.72c-5.99-1.98-9.44-5.59-10.14-10.61C291.58,574.7,292.47,572.66,294.08,570.73z"/>
            </g>
            <g>
                <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M302.03,587.42v0.02c0,0-77.67,4.83-79.87-2.21
                    c-3.07-9.84,0.52-24.71,34.67-32.19l0.49-0.49c8.32,8.18,20.21,13.96,36.76,18.18c-1.61,1.93-2.5,3.96-2.19,6.08
                    C292.58,581.83,296.03,585.44,302.03,587.42z"/>
            </g>
            <g>
                <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M629.8,269.81c16.09,24.22,39.68,51.98,50.15,95.58
                    c-15.39-38.56-54.83-50.43-54.83-50.43c4.11,14.01-0.74,37.09-0.74,37.09c-2.26-12.26-16.46-22.52-16.46-22.52
                    c-2.61,5.01-4.94,27.41-4.94,27.41c-11.68-10.26-14.96-33.24-14.96-33.24c-22.99,21.08-16.2,87.35-16.2,87.35
                    c-38.16-33.11-28.25-88.26-28.25-88.26c-11.98,4.38-19.42,17.51-19.42,17.51c-4.48-7-2.43-31.48-2.43-31.48
                    c11.47-34.66,39.88-67.72,99.03-84.92C620.74,223.91,609.15,238.71,629.8,269.81z"/>
            </g>
            <g>
                <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M685.24,406.85c1.73,68.93-75.62,161.15-191.38,132.52
                    c2.21-4.41,3.69-9,4.57-13.64c0,0,32.89-3.05,45.92-27.83c13.03-24.78,9.65-43.64-11.59-76.65
                    c-12.05-18.74-26.13-66.95-11.05-112.43c0,0-2.05,24.48,2.43,31.48c0,0,7.44-13.13,19.42-17.51c0,0-9.91,55.15,28.25,88.26
                    c0,0-6.79-66.28,16.2-87.35c0,0,3.29,22.99,14.96,33.24c0,0,2.33-22.4,4.94-27.41c0,0,14.2,10.25,16.46,22.52
                    c0,0,4.85-23.08,0.74-37.09c0,0,39.44,11.86,54.83,50.43C682.95,377.81,684.87,391.54,685.24,406.85z"/>
            </g>
            <g>
                <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M427.16,394.5c0,0,7.44,3.92,8.81,5.36c0,0,0.37-10.35-11.2-27.02
                    c-10.14-14.6-17.94-41.98-18.99-51.54c0.07-0.39,0.09-0.77,0.09-1.14v-0.02c5.31-7.9,10.56-15.22,15.75-22.06
                    c0,0,9.51,52.62,26.97,83.71c12.38,22.03,33.15,75.37,43.06,96.72c4.73,10.21,10.25,29.02,6.76,47.23
                    c-0.88,4.64-2.36,9.23-4.57,13.64c-2.21,4.43-5.17,8.65-9.04,12.54c-8.81,8.86-22.35,15.95-42.52,19.46
                    c-5.48-6.62-18.44-9.93-21.37-18.97c-3.98-12.24-2.61-32.12-2.61-32.12c13.73-16.39,26.69-41.29,23.94-60.4
                    c0,0,4.59,3.12,4.59,5.43C446.95,463.5,449.63,419.82,427.16,394.5z"/>
            </g>
            <g>
                <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M296.9,469.33c0,0,2.47-5.57,4.85-6.92c0,0-2.8,22.47,9.02,41.52
                    c2.43,3.87,5.95,7.67,10.09,11.54c0,0,3.54,33.36-3.64,39.33c-5.45,4.53-18.02,9.75-23.14,15.95c-16.55-4.22-28.44-10-36.76-18.18
                    c-12.87-12.63-17.11-31.01-16.55-58.09c0.93-44.62,29.7-94.5,42.75-116.56c22.73-38.44,30.68-73.8,30.68-73.8
                    c8.55,3.38,17.53,7.16,26.95,11.35c0,0-4.01,19.19-20.84,41.59c-7.72,10.26-18.6,38.65-19.79,51.31c0,0,7.76-5.48,11.21-5.88
                    C311.72,402.47,293.75,417.6,296.9,469.33z"/>
            </g>
            <g>
                <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M393.9,309.39c-2.8-0.44-5.92-0.42-9.14,0.19
                    c-5.06,0.93-9.39,3.1-12.38,5.87c0,0-56.93-58.67-128.28-60.77c-70-2.03-96.81,35.37-97.79,36.78c-0.05,0.02-0.05,0.05-0.05,0.05
                    c-9.09-15.22,1.03-49.47,1.03-49.47c-6.97,2.98-14.69,13.47-14.69,13.47c3.66-56.9,50.8-94.78,50.8-94.78s20.16-21.1,35.52-26.18
                    c0,0-4.89-6.48-12.82-14.64c0,0,13.5-8.84,24.55-4.55c7.79,6.88,11.05,11.49,11.05,11.49s40.63-18.61,82.99-22.99
                    c52.57-5.45,71.26,1.54,71.26,1.54s2.05-4.08,5.73-10.03l0.42,0.26c14.38-7.2,25.38-4.85,26.87-4.48
                    c-3.28,5.62-6.5,11.89-9.48,18.91c0,0,26.02,4.94,38.86,9.84c0,0,41.29,16.48,57.46,25.92c16.15,9.42,38,26.25,54.43,49.75
                    c0,0-12.45-4.64-21.19-5.43c0,0,11.21,10.4,15.73,18.63c0,0-0.05,0-0.12-0.02c-3.26-0.93-73.43-20.17-113.87,14.47
                    c-41.45,35.48-56.88,85.95-56.88,85.95L393.9,309.39z"/>
            </g>
            <g>
                <g>
                    <path fill="#231F20" d="M273.93,242.58c0,8.07-6.54,14.61-14.61,14.61c-8.07,0-14.61-6.54-14.61-14.61
                        c0-8.07,6.54-14.62,14.61-14.62C267.39,227.96,273.93,234.51,273.93,242.58z"/>
                </g>
                <g>
                    <path fill="#FFFFFF" d="M268.3,237.33c0,2.06-1.67,3.73-3.73,3.73c-2.06,0-3.73-1.67-3.73-3.73c0-2.06,1.67-3.73,3.73-3.73
                        C266.63,233.6,268.3,235.27,268.3,237.33z"/>
                </g>
            </g>
            <g>
                <g>
                    <path fill="#231F20" d="M445.3,223.91c0,8.07-6.54,14.61-14.61,14.61c-8.07,0-14.62-6.54-14.62-14.61
                        c0-8.07,6.54-14.62,14.62-14.62C438.76,209.29,445.3,215.84,445.3,223.91z"/>
                </g>
                <g>
                    <path fill="#FFFFFF" d="M439.67,218.66c0,2.05-1.67,3.73-3.73,3.73c-2.06,0-3.73-1.67-3.73-3.73c0-2.06,1.67-3.73,3.73-3.73
                        C438,214.93,439.67,216.6,439.67,218.66z"/>
                </g>
            </g>
    
            </svg>
            }
        {/* <svg    
            version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" 
            x="0px" y="0px"
            width="572px"
            height="558px" viewBox="0 0 572 558" 
            // onClick={obtenetID}
        >
            <g id="Capa_1">
                <path fill="#FFFFFF" d="M309.125,353.405c-1.376-1.447-8.814-5.365-8.814-5.365c22.475,25.33,19.794,69.021,19.678,70.841
                    c0-2.31-4.594-5.433-4.594-5.433c2.75,19.12-10.213,44.024-23.949,60.418c-4.684,5.62-9.466,10.234-13.733,13.269
                    c-16.693,11.869-35.629,42.041-35.629,42.041c-6.157-28.565-31.902-45.023-48.109-60.139c-4.148-3.871-7.67-7.672-10.097-11.541
                    c-11.82-19.052-9.022-41.53-9.022-41.53c-2.378,1.354-4.85,6.925-4.85,6.925c-3.148-51.743,14.828-66.875,14.828-66.875
                    c-3.451,0.396-11.215,5.876-11.215,5.876c1.19-12.663,12.081-41.063,19.799-51.325c16.838-22.407,20.846-41.599,20.846-41.599
                    c9.165,4.079,18.748,8.555,28.749,13.478h0.025c3.335,4.499,11.053,6.787,19.401,5.246c8.859-1.658,15.552-7.089,16.489-12.872
                    c1.047,9.562,8.859,36.96,19,51.558C309.495,343.05,309.125,353.405,309.125,353.405"/>
                <path fill="#FFFFFF" stroke="#000000" d="M309.125,353.405c-1.376-1.447-8.814-5.365-8.814-5.365
                    c22.475,25.33,19.794,69.021,19.678,70.841c0-2.31-4.594-5.433-4.594-5.433c2.75,19.12-10.213,44.024-23.949,60.418
                    c-4.684,5.62-9.466,10.234-13.733,13.269c-16.693,11.869-35.629,42.041-35.629,42.041c-6.157-28.565-31.902-45.023-48.109-60.139
                    c-4.148-3.871-7.67-7.672-10.097-11.541c-11.82-19.052-9.022-41.53-9.022-41.53c-2.378,1.354-4.85,6.925-4.85,6.925
                    c-3.148-51.743,14.828-66.875,14.828-66.875c-3.451,0.396-11.215,5.876-11.215,5.876c1.19-12.663,12.081-41.063,19.799-51.325
                    c16.838-22.407,20.846-41.599,20.846-41.599c9.165,4.079,18.748,8.555,28.749,13.478h0.025c3.335,4.499,11.053,6.787,19.401,5.246
                    c8.859-1.658,15.552-7.089,16.489-12.872c1.047,9.562,8.859,36.96,19,51.558C309.495,343.05,309.125,353.405,309.125,353.405z"/>
                <path fill="#FFFFFF;" d="M257.889,263.097c3.224-0.615,6.347-0.635,9.143-0.192c6.25,1.002,11.005,4.359,11.873,9.023
                    c-0.142,0.557-0.121,1.541,0.023,2.89c-0.937,5.785-7.63,11.215-16.489,12.873c-8.348,1.541-16.066-0.747-19.402-5.246
                    c-0.816-1.068-1.348-2.287-1.609-3.588c-0.65-3.478,0.98-7.02,4.082-9.889C248.496,266.194,252.828,264.023,257.889,263.097"/>
                <path fill="#FFFFFF" stroke="#000000" d="M257.889,263.097c3.224-0.615,6.347-0.635,9.143-0.192
                    c6.25,1.002,11.005,4.359,11.873,9.023c-0.142,0.557-0.121,1.541,0.023,2.89c-0.937,5.785-7.63,11.215-16.489,12.873
                    c-8.348,1.541-16.066-0.747-19.402-5.246c-0.816-1.068-1.348-2.287-1.609-3.588c-0.65-3.478,0.98-7.02,4.082-9.889
                    C248.496,266.194,252.828,264.023,257.889,263.097z"/>
                <path fill="#FFFFFF;" d="M278.904,271.928c-0.868-4.664-5.623-8.021-11.873-9.023l0.025-0.21
                    c0,0,15.433-50.486,56.895-85.974c40.457-34.654,110.646-15.413,113.91-14.479c-3.173-0.562-67.226-10.286-143.081,89.351
                    c-5.198,6.837-10.443,14.156-15.759,22.061C279.041,273.095,279.021,272.51,278.904,271.928"/>
                <path fill="#FFFFFF" stroke="#000000" d="M278.904,271.928c-0.868-4.664-5.623-8.021-11.873-9.023
                    l0.025-0.21c0,0,15.433-50.486,56.895-85.974c40.457-34.654,110.646-15.413,113.91-14.479
                    c-3.173-0.562-67.226-10.286-143.081,89.351c-5.198,6.837-10.443,14.156-15.759,22.061
                    C279.041,273.095,279.021,272.51,278.904,271.928z"/>
                <path fill="#FFFFFF" stroke="#000000" d="M19.369,244.971c0.979-1.419,27.797-38.823,97.818-36.795
                    c71.377,2.1,128.323,60.792,128.323,60.792c-3.102,2.869-4.732,6.412-4.082,9.889c0.261,1.301,0.793,2.52,1.609,3.588h-0.024
                    c-10.001-4.922-19.584-9.398-28.749-13.477c-9.421-4.196-18.399-7.974-26.955-11.357"/>
                <path id="linea12" fill="#FFFFFF" stroke="#FFFFFF" d="M63.369,230.382c-32.258,1.676-43.303,13.787-44,14.589"/>
                <path id="linea23" fill="#FFFFFF" stroke="#FFFFFF" d="M125.369,237.507c-26.307-6.344-46.578-7.927-62-7.125"/>
                <path id="linea34" fill="#FFFFFF" stroke="#FFFFFF" d="M187.309,257.611c-23.645-9.361-44.169-15.819-61.939-20.104"/>
                <path fill="#FFFFFF" d="M56.471,114.204c-23.159-32.413-31.786-69.557-29.85-72.637
                    c20.267,2.287,40.154,19.074,52.558,31.808c7.928,8.163,12.825,14.644,12.825,14.644C76.638,93.103,56.471,114.204,56.471,114.204"
                    />
                <path fill="#FFFFFF" stroke="#000000" d="M56.471,114.204c-23.159-32.413-31.786-69.557-29.85-72.637
                    c20.267,2.287,40.154,19.074,52.558,31.808c7.928,8.163,12.825,14.644,12.825,14.644C76.638,93.103,56.471,114.204,56.471,114.204z
                    "/>
                <path fill="#FFFFFF" d="M302.124,44.621c16.442-28.053,34.651-39.359,34.651-39.359s4.502,29.617-5.27,68.112
                    c-12.846-4.896-38.868-9.84-38.868-9.84C295.62,56.514,298.84,50.244,302.124,44.621"/>
                <path fill="#FFFFFF" stroke="#000000" d="M302.124,44.621c16.442-28.053,34.651-39.359,34.651-39.359
                    s4.502,29.617-5.27,68.112c-12.846-4.896-38.868-9.84-38.868-9.84C295.62,56.514,298.84,50.244,302.124,44.621z"/>
                <path fill="#FFFFFF;" d="M103.735,68.828c-11.051-4.294-24.556,4.547-24.556,4.547
                    C66.775,60.641,46.888,43.854,26.621,41.567c1.959-3.1,22.107-8.32,49.969,7.908C88.81,56.585,97.67,63.417,103.735,68.828"/>
                <path fill="#FFFFFF" stroke="#000000" d="M103.735,68.828c-11.051-4.294-24.556,4.547-24.556,4.547
                    C66.775,60.641,46.888,43.854,26.621,41.567c1.959-3.1,22.107-8.32,49.969,7.908C88.81,56.585,97.67,63.417,103.735,68.828z"/>
                <path fill="#FFFFFF" d="M274.82,48.842c10.425-16.739,34.02-48.173,61.956-43.58c0,0-18.209,11.306-34.652,39.359
                    c-1.493-0.372-12.499-2.725-26.884,4.479L274.82,48.842z"/>
                <path fill="#FFFFFF" stroke="#000000" d="M274.82,48.842c10.425-16.739,34.02-48.173,61.956-43.58
                    c0,0-18.209,11.306-34.652,39.359c-1.493-0.372-12.499-2.725-26.884,4.479L274.82,48.842z"/>
                <path fill="#FFFFFF" d="M391.295,539.137c-2.495,6.924-76.229-1.566-76.229-1.566"/>
                <path id="linea1314" fill="#FFFFFF" stroke="#FFFFFF" d="M391.295,539.137c-2.495,6.924-76.229-1.566-76.229-1.566"/>
                <path fill="#FFFFFF" d="M357.974,505.51c33.811,8.887,36.793,23.899,33.321,33.627"/>
                <path id="linea1415" fill="#FFFFFF" stroke="#FFFFFF" d="M357.974,505.51c33.811,8.887,36.793,23.899,33.321,33.627"/>
                <path fill="#FFFFFF" d="M315.066,537.57c1.399-1.051,2.334-2.38,2.639-4.033c0.675-3.523-0.354-6.248-2.267-8.562
                    c20.173-3.514,33.725-10.604,42.535-19.467"/>
                <path fill="#FFFFFF" stroke="#000000" d="M315.066,537.57c1.399-1.051,2.334-2.38,2.639-4.033
                    c0.675-3.523-0.354-6.248-2.267-8.562c20.173-3.514,33.725-10.604,42.535-19.467"/>
                <path fill="#FFFFFF" d="M315.066,537.57c-6.809,5.202-24.414,4.03-31.456,4.664c-8.467,0.77-41.391-0.839-41.391-0.839"/>
                <path id="linea1213" fill="#FFFFFF" stroke="#FFFFFF" d="M315.066,537.57c-6.809,5.202-24.414,4.03-31.456,4.664
                    c-8.467,0.77-41.391-0.839-41.391-0.839"/>
                <path fill="#FFFFFF" d="M242.22,541.396c1.493-0.442-0.137-12.221-0.137-12.221s18.936-30.173,35.629-42.041
                    c4.267-3.033,9.049-7.648,13.734-13.269c0,0-1.372,19.891,2.612,32.132c2.936,9.048,15.901,12.358,21.381,18.979
                    c1.913,2.313,2.939,5.037,2.265,8.561c-0.303,1.654-1.238,2.983-2.637,4.033"/>
                <path fill="#FFFFFF" stroke="#000000" d="M242.22,541.396c1.493-0.442-0.137-12.221-0.137-12.221
                    s18.936-30.173,35.629-42.041c4.267-3.033,9.049-7.648,13.734-13.269c0,0-1.372,19.891,2.612,32.132
                    c2.936,9.048,15.901,12.358,21.381,18.979c1.913,2.313,2.939,5.037,2.265,8.561c-0.303,1.654-1.238,2.983-2.637,4.033"/>
                <path fill="#FFFFFF" d="M242.22,541.396c-1.465,0.42-30.943,0.301-48.499,1.352c-7.695,0.442-13.923-0.142-18.586-1.726"/>
                <path id="linea1112" fill="#FFFFFF" stroke="#FFFFFF" d="M242.22,541.396c-1.465,0.42-30.943,0.301-48.499,1.352
                    c-7.695,0.442-13.923-0.142-18.586-1.726"/>
                <path fill="#FFFFFF" d="M175.135,541.022c-5.995-1.981-9.446-5.597-10.142-10.61c-0.307-2.119,0.582-4.151,2.194-6.084
                    c5.126-6.202,17.696-11.425,23.151-15.952c7.179-5.967,3.636-39.339,3.636-39.339c16.208,15.115,41.953,31.573,48.11,60.139
                    c0,0,1.629,11.778,0.136,12.221"/>
                <path fill="#FFFFFF" stroke="#000000" d="M175.135,541.022c-5.995-1.981-9.446-5.597-10.142-10.61
                    c-0.307-2.119,0.582-4.151,2.194-6.084c5.126-6.202,17.696-11.425,23.151-15.952c7.179-5.967,3.636-39.339,3.636-39.339
                    c16.208,15.115,41.953,31.573,48.11,60.139c0,0,1.629,11.778,0.136,12.221"/>
                <path fill="#FFFFFF" d="M175.135,541.045c0,0-77.696,4.827-79.892-2.215"/>
                <path id="linea1011" fill="#FFFFFF" stroke="#FFFFFF" d="M175.135,541.045c0,0-77.696,4.827-79.892-2.215"/>
                <path fill="#FFFFFF" d="M130.411,506.14c8.323,8.184,20.216,13.966,36.775,18.188c-1.611,1.935-2.5,3.966-2.193,6.084
                    c0.695,5.016,4.147,8.629,10.142,10.611v0.021"/>
                <path fill="#FFFFFF" stroke="#000000" d="M130.411,506.14c8.323,8.184,20.216,13.966,36.775,18.188
                    c-1.611,1.935-2.5,3.966-2.193,6.084c0.695,5.016,4.147,8.629,10.142,10.611v0.021"/>
                <line fill="#FFFFFF" x1="129.92" y1="506.63" x2="130.411" y2="506.14"/>
                <line fill="#FFFFFF" stroke="#000000" x1="129.92" y1="506.63" x2="130.411" y2="506.14"/>
                <path fill="#FFFFFF" d="M95.243,538.831c-3.075-9.845,0.519-24.719,34.677-32.201"/>
                <path id="linea910" fill="#FFFFFF" stroke="#FFFFFF" d="M95.243,538.831c-3.075-9.845,0.519-24.719,34.677-32.201"/>
                <path id="linea2021" fill="#FFFFFF" stroke="#FFFFFF" d="M493.943,177.397c0,0-11.592,14.806,9.066,45.913
                    c3.673,5.529,7.737,11.243,11.947,17.287"/>
                <path id="linea2122" fill="#FFFFFF" stroke="#FFFFFF" d="M394.886,262.343c11.474-34.673,39.898-67.736,99.059-84.946"/>
                <path fill="#FFFFFF" stroke="#000000" d="M553.172,318.917c-15.393-38.572-54.849-50.44-54.849-50.44
                    c4.108,14.014-0.743,37.099-0.743,37.099c-2.262-12.264-16.464-22.522-16.464-22.522c-2.611,5.012-4.941,27.42-4.941,27.42
                    c-11.681-10.258-14.971-33.253-14.971-33.253c-22.994,21.084-16.207,87.38-16.207,87.38c-38.17-33.119-28.261-88.286-28.261-88.286
                    c-11.987,4.383-19.423,17.513-19.423,17.513c-4.48-6.995-2.428-31.482-2.428-31.482"/>
                <path id="linea1920" fill="none" stroke="#FFFFFF" d="M514.956,240.598c14.234,20.441,30.135,44.664,38.216,78.319"/>
                <path id="linea1718" fill="none" stroke="#FFFFFF" d="M544.413,416.51c-14.868,28.852-40.954,55.364-75.56,69.985"/>
                <path id="linea1819" fill="none" stroke="#FFFFFF" d="M553.172,318.917c3.004,12.425,4.919,26.163,5.291,41.481
                    c0.45,17.973-4.473,37.527-14.05,56.111"/>
                <path fill="none" stroke="#000000" d="M394.886,262.343c0,0-2.053,24.487,2.428,31.483
                    c0,0,7.436-13.129,19.424-17.512c0,0-9.91,55.167,28.26,88.285c0,0-6.787-66.296,16.207-87.38c0,0,3.29,22.995,14.971,33.253
                    c0,0,2.33-22.407,4.941-27.42c0,0,14.202,10.259,16.464,22.522c0,0,4.851-23.085,0.744-37.099c0,0,39.456,11.868,54.848,50.439"/>
                <path id="linea2223" fill="none" stroke="#FFFFFF" d="M399.24,361.589c-9.567-23.27-16.65-62.163-4.354-99.245"/>
                <path id="linea2324" fill="none" stroke="#FFFFFF" d="M424.774,430.332c2.18-16.163-3.934-32.358-18.836-55.521
                    c-2.229-3.467-4.526-7.943-6.698-13.222"/>
                <path id="linea2425" fill="none" stroke="#FFFFFF" d="M371.59,479.324c0,0,32.904-3.058,45.938-27.844
                    c3.891-7.399,6.32-14.271,7.247-21.148"/>
                <path id="linea1617" fill="none" stroke="#FFFFFF" d="M468.854,486.496c-28.623,12.094-63.076,16.051-101.833,6.467
                    c2.214-4.407,3.688-8.998,4.569-13.64"/>
                <path id="linea1516" fill="none" stroke="#FFFFFF" d="M367.021,492.963c-2.212,4.434-5.176,8.651-9.047,12.547"/>
                <path fill="none" stroke="#000000" d="M371.59,479.324c-0.883,4.641-2.354,9.23-4.568,13.639"/>
                <path id="linea2526" fill="none" stroke="#FFFFFF" d="M364.831,432.08c4.729,10.213,10.26,29.031,6.76,47.243"/>
                <path id="linea2627" fill="none" stroke="#FFFFFF" d="M346.046,388.256c7.314,17.272,14.208,33.96,18.785,43.823"/>
                <path id="linea2728" fill="none" stroke="#FFFFFF" d="M323.046,337.676c6.521,12.146,15.013,31.714,23,50.58"/>
                <path id="linea2829" fill="none" stroke="#FFFFFF" d="M306.327,297.676c4.111,13.062,9.278,26.694,15.434,37.655
                    c0.42,0.746,0.848,1.529,1.286,2.345"/>
                <path id="linea2930" fill="none" stroke="#FFFFFF" d="M294.78,251.593c0,0,3.99,22.075,11.547,46.083"/>
                <path fill="none" stroke="#000000" d="M357.974,505.51c-8.812,8.86-22.361,15.951-42.534,19.467
                    c-5.479-6.62-18.444-9.93-21.381-18.979c-3.984-12.239-2.612-32.132-2.612-32.132c13.734-16.392,26.697-41.297,23.947-60.418
                    c0,0,4.596,3.124,4.596,5.434c0.116-1.819,2.797-45.512-19.68-70.842c0,0,7.439,3.918,8.815,5.366c0,0,0.37-10.355-11.199-27.029
                    c-10.14-14.597-17.952-41.995-18.999-51.558c0.071-0.395,0.094-0.767,0.094-1.142v-0.022c5.316-7.905,10.561-15.224,15.76-22.061"
                    />
                <path id="linea45" fill="none" stroke="#FFFFFF" d="M168.762,308.532c13.513-28.522,18.547-50.921,18.547-50.921"/>
                <path id="linea56" fill="none" stroke="#FFFFFF" d="M143.243,355.157c5.042-9.543,9.799-17.679,13.376-23.723
                    c4.645-7.854,8.673-15.581,12.143-22.902"/>
                <path id="linea67" fill="none" stroke="#FFFFFF" d="M121.842,404.756c5.805-18.152,13.916-35.43,21.401-49.6"/>
                <path id="linea78" fill="none" stroke="#FFFFFF" d="M113.845,456.445c-0.055-2.708-0.051-5.513,0.009-8.416
                    c0.298-14.246,3.433-29.029,7.988-43.273"/>
                <path id="linea89" fill="none" stroke="#FFFFFF" d="M130.411,506.14c-11.493-11.283-16.105-27.144-16.566-49.694"/>
                <path fill="none" stroke="#000000" d="M187.309,257.611c8.556,3.383,17.534,7.162,26.955,11.357
                    c0,0-4.008,19.191-20.846,41.599c-7.718,10.262-18.609,38.662-19.799,51.325c0,0,7.764-5.48,11.215-5.876
                    c0,0-17.976,15.133-14.828,66.875c0,0,2.472-5.571,4.85-6.926c0,0-2.798,22.479,9.022,41.531c2.427,3.869,5.949,7.67,10.097,11.541
                    c0,0,3.542,33.372-3.637,39.34c-5.455,4.526-18.025,9.749-23.151,15.951c-16.56-4.223-28.453-10.005-36.775-18.189"/>
                <path fill="#FFFFFF;" d="M267.031,262.905c-2.796-0.443-5.919-0.422-9.143,0.192c-5.06,0.927-9.393,3.098-12.378,5.871
                    c0,0-56.946-58.692-128.323-60.792c-70.021-2.027-96.839,35.376-97.819,36.795c-0.047,0.026-0.047,0.051-0.047,0.051
                    c-9.095-15.228,1.027-49.483,1.027-49.483c-6.972,2.986-14.692,13.477-14.692,13.477c3.662-56.921,50.814-94.812,50.814-94.812
                    s20.168-21.101,35.533-26.185c0,0-4.896-6.481-12.824-14.644c0,0,13.505-8.842,24.556-4.547
                    c7.788,6.877,11.053,11.498,11.053,11.498s40.644-18.611,83.012-22.997c52.581-5.453,71.283,1.544,71.283,1.544
                    s2.052-4.082,5.737-10.031l0.42,0.258c14.385-7.204,25.391-4.851,26.884-4.479c-3.285,5.623-6.504,11.893-9.487,18.913
                    c0,0,26.023,4.944,38.868,9.841c0,0,41.3,16.488,57.48,25.929c16.16,9.422,38.01,26.257,54.451,49.763
                    c0,0-12.452-4.641-21.196-5.436c0,0,11.212,10.403,15.738,18.634c0,0-0.047,0-0.117-0.022
                    c-3.265-0.934-73.453-20.176-113.91,14.479c-41.462,35.487-56.895,85.973-56.895,85.973L267.031,262.905z"/>
                <path fill="#FFFFFF" stroke="#000000" d="M267.031,262.905c-2.796-0.443-5.919-0.422-9.143,0.192
                    c-5.06,0.927-9.393,3.098-12.378,5.871c0,0-56.946-58.692-128.323-60.792c-70.021-2.027-96.839,35.376-97.819,36.795
                    c-0.047,0.026-0.047,0.051-0.047,0.051c-9.095-15.228,1.027-49.483,1.027-49.483c-6.972,2.986-14.692,13.477-14.692,13.477
                    c3.662-56.921,50.814-94.812,50.814-94.812s20.168-21.101,35.533-26.185c0,0-4.896-6.481-12.824-14.644
                    c0,0,13.505-8.842,24.556-4.547c7.788,6.877,11.053,11.498,11.053,11.498s40.644-18.611,83.012-22.997
                    c52.581-5.453,71.283,1.544,71.283,1.544s2.052-4.082,5.737-10.031l0.42,0.258c14.385-7.204,25.391-4.851,26.884-4.479
                    c-3.285,5.623-6.504,11.893-9.487,18.913c0,0,26.023,4.944,38.868,9.841c0,0,41.3,16.488,57.48,25.929
                    c16.16,9.422,38.01,26.257,54.451,49.763c0,0-12.452-4.641-21.196-5.436c0,0,11.212,10.403,15.738,18.634c0,0-0.047,0-0.117-0.022
                    c-3.265-0.934-73.453-20.176-113.91,14.479c-41.462,35.487-56.895,85.973-56.895,85.973L267.031,262.905z"/>
                <path fill="#231F20" d="M147.031,196.075c0,8.075-6.542,14.614-14.619,14.614c-8.069,0-14.613-6.539-14.613-14.614
                    c0-8.075,6.544-14.621,14.613-14.621C140.489,181.454,147.031,188,147.031,196.075"/>
                <path fill="#FFFFFF" d="M141.398,190.824c0,2.06-1.67,3.727-3.732,3.727c-2.06,0-3.729-1.667-3.729-3.727
                    c0-2.063,1.67-3.732,3.729-3.732C139.729,187.092,141.398,188.761,141.398,190.824"/>
                <path fill="#231F20" d="M318.452,177.4c0,8.072-6.546,14.616-14.614,14.616c-8.077,0-14.621-6.544-14.621-14.616
                    c0-8.072,6.544-14.621,14.621-14.621C311.906,162.779,318.452,169.328,318.452,177.4"/>
                <path fill="#FFFFFF" d="M312.817,172.152c0,2.055-1.67,3.728-3.731,3.728c-2.061,0-3.729-1.673-3.729-3.728
                    c0-2.065,1.668-3.735,3.729-3.735C311.147,168.417,312.817,170.087,312.817,172.152"/>
                <path onClick={valorPunto} id="1" className="punto" fill="#E30613" d="M22.204,244.975c0,1.565-1.269,2.834-2.835,2.834c-1.565,0-2.834-1.269-2.834-2.834
                    c0-1.566,1.269-2.835,2.834-2.835C20.935,242.14,22.204,243.409,22.204,244.975"/>
                <path onClick={valorPunto} id="2" className="punto" fill="#E30613" d="M66.204,230.22c0,1.566-1.269,2.835-2.835,2.835c-1.565,0-2.834-1.269-2.834-2.835
                    c0-1.565,1.269-2.834,2.834-2.834C64.935,227.386,66.204,228.655,66.204,230.22"/>
                <path onClick={valorPunto} id="3" className="punto" fill="#E30613" d="M128.204,237.47c0,1.566-1.269,2.835-2.835,2.835c-1.565,0-2.834-1.269-2.834-2.835
                    c0-1.565,1.269-2.834,2.834-2.834C126.935,234.636,128.204,235.905,128.204,237.47"/>
                <path onClick={valorPunto} id="4" className="punto" fill="#E30613" d="M190.079,257.611c0,1.566-1.269,2.835-2.835,2.835c-1.565,0-2.834-1.269-2.834-2.835
                    c0-1.565,1.269-2.834,2.834-2.834C188.81,254.777,190.079,256.046,190.079,257.611"/>
                <path onClick={valorPunto} id="5" className="punto" fill="#E30613" d="M171.412,308.444c0,1.565-1.269,2.835-2.834,2.835c-1.566,0-2.835-1.27-2.835-2.835
                    s1.269-2.834,2.835-2.834C170.143,305.61,171.412,306.879,171.412,308.444"/>
                <path onClick={valorPunto} id="6" className="punto" fill="#E30613" d="M146.079,354.944c0,1.565-1.269,2.835-2.835,2.835c-1.565,0-2.834-1.27-2.834-2.835
                    s1.269-2.834,2.834-2.834C144.81,352.11,146.079,353.379,146.079,354.944"/>
                <path onClick={valorPunto} id="7" className="punto" fill="#E30613" d="M124.745,404.778c0,1.564-1.269,2.835-2.834,2.835c-1.566,0-2.835-1.271-2.835-2.835
                    c0-1.565,1.269-2.835,2.835-2.835C123.476,401.943,124.745,403.213,124.745,404.778"/>
                <path onClick={valorPunto} id="8" className="punto" fill="#E30613" d="M116.641,456.444c0,1.565-1.269,2.835-2.835,2.835c-1.565,0-2.834-1.27-2.834-2.835
                    s1.269-2.834,2.834-2.834C115.372,453.61,116.641,454.879,116.641,456.444"/>
                <path onClick={valorPunto} id="9" className="punto" fill="#E30613" d="M132.808,506.14c0,1.564-1.269,2.835-2.834,2.835c-1.566,0-2.835-1.271-2.835-2.835
                    c0-1.565,1.269-2.835,2.835-2.835C131.539,503.305,132.808,504.574,132.808,506.14"/>
                <path onClick={valorPunto} id="10" className="punto" fill="#E30613" d="M98.641,539.02c0,1.564-1.269,2.835-2.835,2.835c-1.565,0-2.834-1.271-2.834-2.835
                    c0-1.565,1.269-2.834,2.834-2.834C97.372,536.186,98.641,537.454,98.641,539.02"/>
                <path onClick={valorPunto} id="11" className="punto" fill="#E30613" d="M176.641,541.067c0,1.564-1.269,2.835-2.835,2.835c-1.565,0-2.834-1.271-2.834-2.835
                    c0-1.565,1.269-2.834,2.834-2.834C175.372,538.233,176.641,539.502,176.641,541.067"/>
                <path onClick={valorPunto} id="12" className="punto" fill="#E30613" d="M245.685,541.067c0,1.564-1.269,2.835-2.834,2.835c-1.566,0-2.835-1.271-2.835-2.835
                    c0-1.565,1.269-2.834,2.835-2.834C244.416,538.233,245.685,539.502,245.685,541.067"/>
                <path onClick={valorPunto} id="13" className="punto"  fill="#E30613" d="M318.518,538.233c0,1.564-1.27,2.834-2.835,2.834c-1.564,0-2.834-1.27-2.834-2.834
                    c0-1.565,1.27-2.835,2.834-2.835C317.248,535.398,318.518,536.668,318.518,538.233"/>
                <path onClick={valorPunto} id="14" className="punto"  fill="#E30613" d="M394.186,539.233c0,1.564-1.27,2.834-2.835,2.834c-1.564,0-2.834-1.27-2.834-2.834
                    c0-1.565,1.27-2.835,2.834-2.835C392.916,536.398,394.186,537.668,394.186,539.233"/>
                <path onClick={valorPunto} id="15" className="punto" fill="#E30613;" d="M361.519,505.51c0,1.564-1.269,2.834-2.834,2.834s-2.835-1.27-2.835-2.834
                    c0-1.566,1.27-2.835,2.835-2.835S361.519,503.943,361.519,505.51"/>
                <path onClick={valorPunto} id="16" className="punto"  fill="#E30613" d="M369.854,493.176c0,1.564-1.269,2.835-2.834,2.835s-2.835-1.271-2.835-2.835
                    c0-1.565,1.27-2.834,2.835-2.834S369.854,491.61,369.854,493.176"/>
                <path onClick={valorPunto} id="17" className="punto"  fill="#E30613" d="M471.688,486.676c0,1.564-1.27,2.835-2.835,2.835c-1.564,0-2.835-1.271-2.835-2.835
                    c0-1.565,1.271-2.834,2.835-2.834C470.419,483.842,471.688,485.11,471.688,486.676"/>
                <path onClick={valorPunto} id="18" className="punto"  fill="#E30613" d="M547.188,416.51c0,1.564-1.27,2.834-2.835,2.834c-1.564,0-2.835-1.27-2.835-2.834
                    c0-1.566,1.271-2.835,2.835-2.835C545.919,413.675,547.188,414.943,547.188,416.51"/>
                <path onClick={valorPunto} id="24" className="punto"  fill="#E30613" d="M427.688,430.343c0,1.564-1.27,2.835-2.835,2.835c-1.564,0-2.835-1.271-2.835-2.835
                    c0-1.565,1.271-2.835,2.835-2.835C426.419,427.508,427.688,428.777,427.688,430.343"/>
                <path onClick={valorPunto} id="23" className="punto"  fill="#E30613" d="M401.688,361.766c0,1.564-1.27,2.834-2.835,2.834c-1.564,0-2.835-1.27-2.835-2.834
                    c0-1.566,1.271-2.835,2.835-2.835C400.419,358.931,401.688,360.199,401.688,361.766"/>
                <path onClick={valorPunto} id="19" className="punto"  fill="#E30613" d="M556.007,317.932c0,1.564-1.27,2.835-2.835,2.835c-1.564,0-2.835-1.271-2.835-2.835
                    c0-1.565,1.271-2.835,2.835-2.835C554.737,315.097,556.007,316.366,556.007,317.932"/>
                <path onClick={valorPunto} id="22" className="punto"  fill="#E30613" d="M397.251,263.098c0,1.565-1.27,2.834-2.835,2.834c-1.564,0-2.834-1.269-2.834-2.834
                    c0-1.566,1.27-2.835,2.834-2.835C395.981,260.263,397.251,261.532,397.251,263.098"/>
                <path onClick={valorPunto} id="20" className="punto"  fill="#E30613" d="M518.251,240.598c0,1.565-1.27,2.834-2.835,2.834c-1.564,0-2.834-1.269-2.834-2.834
                    c0-1.566,1.27-2.835,2.834-2.835C516.981,237.763,518.251,239.032,518.251,240.598"/>
                <path onClick={valorPunto} id="25" className="punto"  fill="#E30613" d="M374.354,479.01c0,1.564-1.269,2.834-2.834,2.834s-2.835-1.27-2.835-2.834
                    c0-1.566,1.27-2.835,2.835-2.835S374.354,477.443,374.354,479.01"/>
                <path onClick={valorPunto} id="26" className="punto"  fill="#E30613" d="M367.853,433.178c0,1.564-1.27,2.834-2.835,2.834c-1.564,0-2.835-1.27-2.835-2.834
                    c0-1.566,1.271-2.835,2.835-2.835C366.583,430.343,367.853,431.611,367.853,433.178"/>
                <path onClick={valorPunto} id="28" className="punto"  fill="#E30613" d="M325.811,337.676c0,1.564-1.27,2.835-2.835,2.835c-1.564,0-2.835-1.271-2.835-2.835
                    c0-1.565,1.271-2.834,2.835-2.834C324.541,334.842,325.811,336.11,325.811,337.676"/>
                <path onClick={valorPunto} id="27" className="punto"  fill="#E30613" d="M348.811,388.285c0,1.564-1.27,2.835-2.835,2.835c-1.564,0-2.835-1.271-2.835-2.835
                    c0-1.565,1.271-2.835,2.835-2.835C347.541,385.45,348.811,386.72,348.811,388.285"/>
                <path onClick={valorPunto} id="29" className="punto"  fill="#E30613" d="M309.353,297.676c0,1.564-1.27,2.835-2.835,2.835c-1.564,0-2.835-1.271-2.835-2.835
                    c0-1.565,1.271-2.834,2.835-2.834C308.083,294.842,309.353,296.11,309.353,297.676"/>
                <path onClick={valorPunto} id="30" className="punto"  fill="#E30613" d="M297.853,251.593c0,1.566-1.27,2.835-2.835,2.835c-1.564,0-2.835-1.269-2.835-2.835
                    c0-1.565,1.271-2.834,2.835-2.834C296.583,248.759,297.853,250.028,297.853,251.593"/>
                <path onClick={valorPunto} id="21" className="punto"  fill="#E30613" d="M497.872,177.397c0,2-1.622,3.621-3.622,3.621s-3.621-1.621-3.621-3.621s1.621-3.622,3.621-3.622
                    S497.872,175.397,497.872,177.397"/>
                <g>
                    <path d="M19.163,252.476h-0.02l-1.129,0.609l-0.17-0.669l1.419-0.76h0.75v6.497h-0.849V252.476z"/>
                </g>
                <g>
                    <path d="M61.253,241.733v-0.54l0.689-0.67c1.659-1.579,2.409-2.418,2.419-3.398c0-0.66-0.32-1.27-1.29-1.27
                        c-0.589,0-1.079,0.3-1.379,0.55l-0.28-0.62c0.45-0.38,1.089-0.66,1.839-0.66c1.399,0,1.989,0.959,1.989,1.889
                        c0,1.199-0.87,2.168-2.239,3.488l-0.52,0.48v0.02h2.918v0.729H61.253z"/>
                </g>
                <g>
                    <path d="M123.463,248.974c0.25,0.16,0.83,0.41,1.439,0.41c1.129,0,1.479-0.72,1.469-1.259c-0.01-0.91-0.83-1.299-1.679-1.299
                        h-0.49v-0.66h0.49c0.64,0,1.449-0.33,1.449-1.1c0-0.52-0.33-0.979-1.14-0.979c-0.52,0-1.02,0.23-1.299,0.43l-0.23-0.64
                        c0.34-0.25,1-0.5,1.699-0.5c1.279,0,1.859,0.76,1.859,1.549c0,0.669-0.399,1.239-1.199,1.529v0.02
                        c0.8,0.16,1.449,0.76,1.449,1.669c0,1.04-0.81,1.949-2.369,1.949c-0.729,0-1.369-0.23-1.689-0.439L123.463,248.974z"/>
                </g>
                <g>
                    <path d="M179.392,263.823v-1.769h-3.019v-0.58l2.898-4.148h0.95v4.038h0.909v0.689h-0.909v1.769H179.392z M179.392,261.364v-2.169
                        c0-0.34,0.01-0.68,0.03-1.02h-0.03c-0.2,0.38-0.36,0.66-0.54,0.959l-1.589,2.209v0.02H179.392z"/>
                </g>
                <g>
                    <path d="M163.051,305.176h-2.479l-0.25,1.669c0.15-0.02,0.29-0.04,0.53-0.04c0.5,0,1,0.11,1.399,0.35
                        c0.51,0.29,0.93,0.85,0.93,1.67c0,1.269-1.009,2.219-2.418,2.219c-0.71,0-1.31-0.2-1.619-0.4l0.22-0.67
                        c0.27,0.16,0.8,0.36,1.389,0.36c0.83,0,1.539-0.54,1.539-1.409c-0.01-0.84-0.569-1.439-1.869-1.439c-0.37,0-0.66,0.04-0.899,0.07
                        l0.419-3.118h3.108V305.176z"/>
                </g>
                <g>
                    <path d="M138.202,349.605c-0.18-0.01-0.41,0-0.66,0.04c-1.379,0.23-2.109,1.239-2.259,2.309h0.03
                        c0.31-0.409,0.849-0.749,1.569-0.749c1.149,0,1.959,0.829,1.959,2.099c0,1.189-0.81,2.289-2.159,2.289
                        c-1.389,0-2.299-1.08-2.299-2.769c0-1.279,0.46-2.289,1.1-2.929c0.54-0.529,1.259-0.859,2.079-0.96
                        c0.26-0.039,0.48-0.05,0.64-0.05V349.605z M137.952,353.354c0-0.93-0.53-1.489-1.339-1.489c-0.529,0-1.019,0.33-1.259,0.8
                        c-0.06,0.1-0.1,0.229-0.1,0.39c0.02,1.069,0.51,1.859,1.429,1.859C137.442,354.913,137.952,354.283,137.952,353.354z"/>
                </g>
                <g>
                    <path d="M115.911,399.677v0.579l-2.829,5.917h-0.909l2.818-5.747v-0.02h-3.178v-0.729H115.911z"/>
                </g>
                <g>
                    <path d="M105.164,457.283c0-0.819,0.49-1.398,1.29-1.738l-0.01-0.03c-0.719-0.34-1.029-0.899-1.029-1.459
                        c0-1.03,0.87-1.729,2.009-1.729c1.259,0,1.889,0.79,1.889,1.6c0,0.549-0.27,1.139-1.069,1.519v0.03
                        c0.81,0.319,1.309,0.89,1.309,1.679c0,1.13-0.969,1.89-2.208,1.89C105.983,459.043,105.164,458.233,105.164,457.283z
                        M108.662,457.243c0-0.789-0.549-1.169-1.429-1.419c-0.759,0.22-1.169,0.72-1.169,1.34c-0.03,0.659,0.47,1.239,1.299,1.239
                        C108.152,458.403,108.662,457.913,108.662,457.243z M106.243,453.995c0,0.649,0.49,1,1.239,1.199
                        c0.56-0.189,0.989-0.589,0.989-1.179c0-0.52-0.31-1.06-1.099-1.06C106.643,452.956,106.243,453.436,106.243,453.995z"/>
                </g>
                <g>
                    <path d="M119.923,506.063c0.19,0.021,0.41,0,0.709-0.029c0.51-0.07,0.99-0.28,1.359-0.63c0.43-0.39,0.74-0.96,0.859-1.729h-0.03
                        c-0.36,0.44-0.879,0.7-1.529,0.7c-1.169,0-1.919-0.88-1.919-1.989c0-1.229,0.89-2.309,2.219-2.309c1.33,0,2.149,1.079,2.149,2.738
                        c0,1.43-0.479,2.429-1.119,3.049c-0.5,0.489-1.189,0.789-1.889,0.869c-0.32,0.05-0.6,0.061-0.81,0.05V506.063z M120.243,502.325
                        c0,0.81,0.49,1.379,1.25,1.379c0.589,0,1.049-0.29,1.279-0.68c0.05-0.08,0.08-0.18,0.08-0.319c0-1.109-0.41-1.959-1.329-1.959
                        C120.773,500.746,120.243,501.405,120.243,502.325z"/>
                </g>
                <g>
                    <path d="M84.593,540.596h-0.02l-1.129,0.609l-0.17-0.67l1.419-0.759h0.75v6.496h-0.849V540.596z"/>
                    <path d="M92.131,542.954c0,2.209-0.82,3.429-2.259,3.429c-1.27,0-2.129-1.189-2.149-3.339c0-2.179,0.939-3.378,2.259-3.378
                        C91.352,539.666,92.131,540.886,92.131,542.954z M88.603,543.055c0,1.688,0.52,2.648,1.319,2.648c0.899,0,1.329-1.05,1.329-2.709
                        c0-1.599-0.41-2.648-1.319-2.648C89.163,540.346,88.603,541.285,88.603,543.055z"/>
                </g>
                <g>
                    <path d="M170.343,546.716h-0.02l-1.129,0.609l-0.17-0.67l1.419-0.759h0.75v6.496h-0.849V546.716z"/>
                    <path d="M175.473,546.716h-0.02l-1.129,0.609l-0.17-0.67l1.419-0.759h0.75v6.496h-0.849V546.716z"/>
                </g>
                <g>
                    <path d="M238.462,546.716h-0.02l-1.129,0.609l-0.17-0.67l1.419-0.759h0.75v6.496h-0.849V546.716z"/>
                    <path d="M241.683,552.393v-0.54l0.689-0.669c1.659-1.579,2.409-2.419,2.419-3.398c0-0.66-0.32-1.27-1.29-1.27
                        c-0.589,0-1.079,0.3-1.379,0.55l-0.28-0.619c0.45-0.38,1.089-0.66,1.839-0.66c1.399,0,1.989,0.96,1.989,1.889
                        c0,1.2-0.87,2.169-2.239,3.488l-0.52,0.479v0.021h2.918v0.729H241.683z"/>
                </g>
                <g>
                    <path d="M313.962,542.996h-0.02l-1.13,0.609l-0.17-0.67l1.42-0.759h0.749v6.496h-0.85V542.996z"/>
                    <path d="M317.393,547.663c0.25,0.16,0.83,0.41,1.439,0.41c1.13,0,1.479-0.72,1.47-1.26c-0.011-0.909-0.83-1.299-1.68-1.299h-0.489
                        v-0.66h0.489c0.64,0,1.449-0.329,1.449-1.099c0-0.52-0.329-0.979-1.139-0.979c-0.521,0-1.02,0.229-1.3,0.43l-0.229-0.64
                        c0.34-0.25,0.999-0.5,1.699-0.5c1.279,0,1.858,0.76,1.858,1.549c0,0.67-0.399,1.239-1.199,1.529v0.021
                        c0.8,0.159,1.449,0.76,1.449,1.669c0,1.039-0.81,1.949-2.369,1.949c-0.729,0-1.369-0.23-1.688-0.44L317.393,547.663z"/>
                </g>
                <g>
                    <path d="M389.642,544.376h-0.02l-1.13,0.609l-0.17-0.67l1.42-0.759h0.749v6.496h-0.85V544.376z"/>
                    <path d="M395.581,550.053v-1.769h-3.018v-0.58l2.897-4.147h0.95v4.037h0.909v0.69h-0.909v1.769H395.581z M395.581,547.594v-2.169
                        c0-0.339,0.011-0.679,0.03-1.019h-0.03c-0.199,0.379-0.359,0.659-0.539,0.959l-1.59,2.209v0.02H395.581z"/>
                </g>
                <g>
                    <path d="M345.892,500.386h-0.02l-1.13,0.609l-0.17-0.67l1.42-0.759h0.749v6.496h-0.85V500.386z"/>
                    <path d="M352.99,500.306h-2.479l-0.25,1.669c0.15-0.02,0.29-0.04,0.53-0.04c0.5,0,0.999,0.11,1.399,0.35
                        c0.51,0.29,0.93,0.85,0.93,1.67c0,1.269-1.01,2.219-2.419,2.219c-0.71,0-1.31-0.2-1.619-0.4l0.22-0.669
                        c0.27,0.159,0.8,0.359,1.39,0.359c0.829,0,1.539-0.54,1.539-1.409c-0.011-0.84-0.57-1.439-1.869-1.439
                        c-0.37,0-0.66,0.04-0.899,0.07l0.42-3.118h3.107V500.306z"/>
                </g>
                <g>
                    <path d="M354.512,488.756h-0.02l-1.13,0.609l-0.17-0.67l1.42-0.759h0.749v6.496h-0.85V488.756z"/>
                    <path d="M361.441,488.556c-0.181-0.01-0.41,0-0.66,0.04c-1.379,0.23-2.108,1.239-2.259,2.309h0.03
                        c0.31-0.409,0.85-0.749,1.569-0.749c1.149,0,1.958,0.829,1.958,2.099c0,1.189-0.809,2.289-2.158,2.289
                        c-1.39,0-2.299-1.08-2.299-2.769c0-1.279,0.46-2.289,1.1-2.929c0.539-0.529,1.259-0.859,2.078-0.96
                        c0.261-0.039,0.48-0.05,0.641-0.05V488.556z M361.191,492.304c0-0.93-0.53-1.489-1.34-1.489c-0.529,0-1.02,0.33-1.259,0.8
                        c-0.061,0.1-0.101,0.229-0.101,0.39c0.021,1.069,0.51,1.859,1.43,1.859C360.682,493.863,361.191,493.233,361.191,492.304z"/>
                </g>
                <g>
                    <path d="M467.262,491.937h-0.02l-1.13,0.609l-0.17-0.67l1.42-0.759h0.749v6.496h-0.85V491.937z"/>
                    <path d="M474.7,491.117v0.579l-2.828,5.917h-0.909l2.818-5.747v-0.02h-3.179v-0.729H474.7z"/>
                </g>
                <g>
                    <path d="M548.932,420.776h-0.02l-1.13,0.609l-0.17-0.67l1.42-0.759h0.749v6.496h-0.85V420.776z"/>
                    <path d="M552.073,424.804c0-0.819,0.489-1.398,1.289-1.738l-0.01-0.03c-0.72-0.34-1.029-0.899-1.029-1.459
                        c0-1.03,0.869-1.729,2.009-1.729c1.259,0,1.889,0.79,1.889,1.6c0,0.549-0.27,1.139-1.069,1.519v0.03
                        c0.81,0.319,1.31,0.89,1.31,1.679c0,1.13-0.97,1.89-2.209,1.89C552.893,426.563,552.073,425.754,552.073,424.804z
                        M555.571,424.764c0-0.789-0.55-1.169-1.43-1.419c-0.76,0.22-1.169,0.72-1.169,1.34c-0.03,0.659,0.47,1.239,1.299,1.239
                        C555.062,425.924,555.571,425.434,555.571,424.764z M553.152,421.516c0,0.649,0.489,1,1.239,1.199
                        c0.56-0.189,0.989-0.589,0.989-1.179c0-0.521-0.31-1.06-1.1-1.06C553.552,420.477,553.152,420.956,553.152,421.516z"/>
                </g>
                <g>
                    <path d="M558.853,314.756h-0.02l-1.13,0.609l-0.17-0.67l1.42-0.759h0.749v6.496h-0.85V314.756z"/>
                    <path d="M562.584,319.813c0.189,0.021,0.409,0,0.709-0.029c0.51-0.07,0.989-0.28,1.359-0.63c0.43-0.39,0.739-0.96,0.859-1.729
                        h-0.03c-0.359,0.44-0.879,0.7-1.528,0.7c-1.17,0-1.919-0.88-1.919-1.989c0-1.229,0.889-2.309,2.219-2.309
                        c1.329,0,2.148,1.079,2.148,2.738c0,1.43-0.479,2.429-1.119,3.049c-0.5,0.489-1.189,0.789-1.89,0.869
                        c-0.319,0.05-0.6,0.061-0.809,0.05V319.813z M562.903,316.075c0,0.81,0.489,1.379,1.249,1.379c0.59,0,1.05-0.29,1.279-0.68
                        c0.05-0.08,0.08-0.18,0.08-0.319c0-1.109-0.409-1.959-1.329-1.959C563.433,314.496,562.903,315.155,562.903,316.075z"/>
                </g>
                <g>
                    <path d="M519.773,241.153v-0.54l0.689-0.67c1.659-1.579,2.408-2.418,2.418-3.398c0-0.66-0.319-1.27-1.289-1.27
                        c-0.59,0-1.079,0.3-1.379,0.55l-0.28-0.62c0.45-0.38,1.09-0.66,1.839-0.66c1.399,0,1.989,0.959,1.989,1.889
                        c0,1.199-0.869,2.168-2.238,3.488l-0.521,0.48v0.02h2.919v0.729H519.773z"/>
                    <path d="M529.221,237.834c0,2.209-0.819,3.428-2.259,3.428c-1.27,0-2.129-1.189-2.149-3.338c0-2.179,0.939-3.378,2.259-3.378
                        C528.441,234.546,529.221,235.766,529.221,237.834z M525.692,237.935c0,1.689,0.52,2.648,1.319,2.648
                        c0.899,0,1.329-1.049,1.329-2.708c0-1.599-0.409-2.648-1.319-2.648C526.252,235.226,525.692,236.166,525.692,237.935z"/>
                </g>
                <g>
                    <path d="M500.604,178.483v-0.54l0.689-0.67c1.659-1.579,2.408-2.418,2.418-3.398c0-0.66-0.319-1.27-1.289-1.27
                        c-0.59,0-1.079,0.3-1.379,0.55l-0.28-0.62c0.45-0.38,1.09-0.66,1.839-0.66c1.399,0,1.989,0.959,1.989,1.889
                        c0,1.199-0.869,2.168-2.238,3.488l-0.521,0.48v0.02h2.919v0.729H500.604z"/>
                    <path d="M507.642,172.806h-0.02l-1.13,0.609l-0.17-0.669l1.42-0.76h0.749v6.497h-0.85V172.806z"/>
                </g>
                <g>
                    <path d="M379.733,265.733v-0.54l0.689-0.67c1.659-1.579,2.408-2.418,2.418-3.398c0-0.66-0.319-1.27-1.289-1.27
                        c-0.59,0-1.079,0.3-1.379,0.55l-0.28-0.62c0.45-0.38,1.09-0.66,1.839-0.66c1.399,0,1.989,0.959,1.989,1.889
                        c0,1.199-0.869,2.168-2.238,3.488l-0.521,0.48v0.02h2.919v0.729H379.733z"/>
                    <path d="M384.863,265.733v-0.54l0.689-0.67c1.659-1.579,2.408-2.418,2.418-3.398c0-0.66-0.319-1.27-1.289-1.27
                        c-0.59,0-1.079,0.3-1.379,0.55l-0.28-0.62c0.45-0.38,1.09-0.66,1.839-0.66c1.399,0,1.989,0.959,1.989,1.889
                        c0,1.199-0.869,2.168-2.238,3.488l-0.521,0.48v0.02h2.919v0.729H384.863z"/>
                </g>
                <g>
                    <path d="M385.354,365.152v-0.54l0.689-0.669c1.659-1.579,2.408-2.419,2.418-3.398c0-0.66-0.319-1.27-1.289-1.27
                        c-0.59,0-1.079,0.3-1.379,0.55l-0.28-0.619c0.45-0.38,1.09-0.66,1.839-0.66c1.399,0,1.989,0.96,1.989,1.889
                        c0,1.2-0.869,2.169-2.238,3.488l-0.521,0.479v0.021h2.919v0.729H385.354z"/>
                    <path d="M390.692,364.143c0.25,0.16,0.83,0.41,1.439,0.41c1.13,0,1.479-0.72,1.47-1.26c-0.011-0.909-0.83-1.299-1.68-1.299h-0.489
                        v-0.66h0.489c0.64,0,1.449-0.329,1.449-1.099c0-0.521-0.329-0.979-1.139-0.979c-0.521,0-1.02,0.229-1.3,0.43l-0.229-0.64
                        c0.34-0.25,0.999-0.5,1.699-0.5c1.279,0,1.858,0.76,1.858,1.549c0,0.67-0.399,1.239-1.199,1.529v0.021
                        c0.8,0.159,1.449,0.76,1.449,1.669c0,1.039-0.81,1.949-2.369,1.949c-0.729,0-1.369-0.23-1.688-0.44L390.692,364.143z"/>
                </g>
                <g>
                    <path d="M411.714,432.843v-0.54l0.689-0.669c1.659-1.579,2.408-2.419,2.418-3.398c0-0.66-0.319-1.27-1.289-1.27
                        c-0.59,0-1.079,0.3-1.379,0.55l-0.28-0.619c0.45-0.38,1.09-0.66,1.839-0.66c1.399,0,1.989,0.96,1.989,1.889
                        c0,1.199-0.869,2.169-2.238,3.488l-0.521,0.479v0.021h2.919v0.729H411.714z"/>
                    <path d="M419.562,432.843v-1.769h-3.018v-0.58l2.897-4.147h0.95v4.037h0.909v0.69h-0.909v1.769H419.562z M419.562,430.384v-2.169
                        c0-0.339,0.011-0.679,0.03-1.019h-0.03c-0.199,0.379-0.359,0.659-0.539,0.959l-1.59,2.209v0.02H419.562z"/>
                </g>
                <g>
                    <path d="M369.674,433.732v-0.54l0.689-0.669c1.659-1.579,2.408-2.419,2.418-3.398c0-0.66-0.319-1.27-1.289-1.27
                        c-0.59,0-1.079,0.3-1.379,0.55l-0.28-0.619c0.45-0.38,1.09-0.66,1.839-0.66c1.399,0,1.989,0.96,1.989,1.889
                        c0,1.2-0.869,2.169-2.238,3.488l-0.521,0.479v0.021h2.919v0.729H369.674z"/>
                    <path d="M378.512,427.855c-0.181-0.01-0.41,0-0.66,0.04c-1.379,0.23-2.108,1.239-2.259,2.309h0.03
                        c0.31-0.409,0.85-0.749,1.569-0.749c1.149,0,1.958,0.829,1.958,2.099c0,1.189-0.809,2.289-2.158,2.289
                        c-1.39,0-2.299-1.08-2.299-2.769c0-1.279,0.46-2.289,1.1-2.929c0.539-0.529,1.259-0.859,2.078-0.96
                        c0.261-0.039,0.48-0.05,0.641-0.05V427.855z M378.262,431.604c0-0.93-0.53-1.489-1.34-1.489c-0.529,0-1.02,0.33-1.259,0.8
                        c-0.061,0.1-0.101,0.229-0.101,0.39c0.021,1.069,0.51,1.859,1.43,1.859C377.752,433.163,378.262,432.533,378.262,431.604z"/>
                </g>
                <g>
                    <path d="M350.504,390.063v-0.54l0.689-0.669c1.659-1.579,2.408-2.419,2.418-3.398c0-0.66-0.319-1.27-1.289-1.27
                        c-0.59,0-1.079,0.3-1.379,0.55l-0.28-0.619c0.45-0.38,1.09-0.66,1.839-0.66c1.399,0,1.989,0.96,1.989,1.889
                        c0,1.2-0.869,2.169-2.238,3.488l-0.521,0.479v0.021h2.919v0.729H350.504z"/>
                    <path d="M359.851,383.566v0.579l-2.828,5.917h-0.909l2.818-5.747v-0.02h-3.179v-0.729H359.851z"/>
                </g>
                <g>
                    <path d="M327.504,338.232v-0.54l0.689-0.669c1.659-1.579,2.408-2.419,2.418-3.398c0-0.66-0.319-1.27-1.289-1.27
                        c-0.59,0-1.079,0.3-1.379,0.55l-0.28-0.619c0.45-0.38,1.09-0.66,1.839-0.66c1.399,0,1.989,0.96,1.989,1.889
                        c0,1.2-0.869,2.169-2.238,3.488l-0.521,0.479v0.021h2.919v0.729H327.504z"/>
                    <path d="M332.554,336.583c0-0.819,0.489-1.398,1.289-1.738l-0.01-0.03c-0.72-0.34-1.029-0.899-1.029-1.459
                        c0-1.03,0.869-1.729,2.009-1.729c1.259,0,1.889,0.79,1.889,1.6c0,0.549-0.27,1.139-1.069,1.519v0.03
                        c0.81,0.319,1.31,0.89,1.31,1.679c0,1.13-0.97,1.89-2.209,1.89C333.373,338.343,332.554,337.533,332.554,336.583z
                        M336.052,336.543c0-0.789-0.55-1.169-1.43-1.419c-0.76,0.22-1.169,0.72-1.169,1.34c-0.03,0.659,0.47,1.239,1.299,1.239
                        C335.542,337.703,336.052,337.213,336.052,336.543z M333.633,333.295c0,0.649,0.489,1,1.239,1.199
                        c0.56-0.189,0.989-0.589,0.989-1.179c0-0.521-0.31-1.06-1.1-1.06C334.032,332.256,333.633,332.735,333.633,333.295z"/>
                </g>
                <g>
                    <path d="M311.334,300.183v-0.54l0.689-0.669c1.659-1.579,2.408-2.419,2.418-3.398c0-0.66-0.319-1.27-1.289-1.27
                        c-0.59,0-1.079,0.3-1.379,0.55l-0.28-0.619c0.45-0.38,1.09-0.66,1.839-0.66c1.399,0,1.989,0.96,1.989,1.889
                        c0,1.199-0.869,2.169-2.238,3.488l-0.521,0.479v0.021h2.919v0.729H311.334z"/>
                    <path d="M316.973,299.563c0.19,0.021,0.41,0,0.71-0.029c0.51-0.07,0.989-0.28,1.359-0.63c0.43-0.39,0.739-0.96,0.859-1.729h-0.03
                        c-0.359,0.44-0.879,0.7-1.528,0.7c-1.17,0-1.919-0.88-1.919-1.989c0-1.229,0.889-2.309,2.219-2.309
                        c1.329,0,2.148,1.079,2.148,2.738c0,1.43-0.479,2.429-1.119,3.049c-0.5,0.489-1.189,0.789-1.89,0.869
                        c-0.319,0.05-0.6,0.061-0.81,0.05V299.563z M317.293,295.825c0,0.81,0.489,1.379,1.249,1.379c0.59,0,1.05-0.29,1.279-0.68
                        c0.05-0.08,0.08-0.18,0.08-0.319c0-1.109-0.409-1.959-1.329-1.959C317.822,294.246,317.293,294.905,317.293,295.825z"/>
                </g>
                <g>
                    <path d="M300.303,254.874c0.25,0.16,0.83,0.41,1.439,0.41c1.13,0,1.479-0.72,1.47-1.259c-0.011-0.91-0.83-1.299-1.68-1.299h-0.489
                        v-0.66h0.489c0.64,0,1.449-0.33,1.449-1.1c0-0.52-0.329-0.979-1.139-0.979c-0.521,0-1.02,0.23-1.3,0.43l-0.229-0.64
                        c0.34-0.25,0.999-0.5,1.699-0.5c1.279,0,1.858,0.76,1.858,1.549c0,0.669-0.399,1.239-1.199,1.529v0.02
                        c0.8,0.16,1.449,0.76,1.449,1.669c0,1.039-0.81,1.949-2.369,1.949c-0.729,0-1.369-0.23-1.688-0.439L300.303,254.874z"/>
                    <path d="M309.541,252.564c0,2.209-0.819,3.428-2.259,3.428c-1.27,0-2.129-1.189-2.149-3.338c0-2.179,0.939-3.378,2.259-3.378
                        C308.762,249.276,309.541,250.496,309.541,252.564z M306.013,252.665c0,1.689,0.52,2.648,1.319,2.648
                        c0.899,0,1.329-1.049,1.329-2.708c0-1.599-0.409-2.648-1.319-2.648C306.572,249.956,306.013,250.896,306.013,252.665z"/>
                </g>
                <g>
                    <path d="M356.834,480.993v-0.54l0.689-0.669c1.659-1.579,2.408-2.419,2.418-3.398c0-0.66-0.319-1.27-1.289-1.27
                        c-0.59,0-1.079,0.3-1.379,0.55l-0.28-0.619c0.45-0.38,1.09-0.66,1.839-0.66c1.399,0,1.989,0.96,1.989,1.889
                        c0,1.199-0.869,2.169-2.238,3.488l-0.521,0.479v0.021h2.919v0.729H356.834z"/>
                    <path d="M365.841,475.236h-2.479l-0.249,1.669c0.149-0.02,0.289-0.04,0.529-0.04c0.5,0,0.999,0.11,1.399,0.35
                        c0.51,0.29,0.93,0.85,0.93,1.67c0,1.269-1.01,2.219-2.419,2.219c-0.71,0-1.31-0.2-1.619-0.4l0.22-0.67
                        c0.27,0.16,0.8,0.36,1.39,0.36c0.829,0,1.539-0.54,1.539-1.409c-0.011-0.84-0.57-1.439-1.869-1.439c-0.37,0-0.66,0.04-0.899,0.07
                        l0.42-3.118h3.107V475.236z"/>
                </g>
            </g>            
        </svg>               */}

        {/* <svg    
            version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" 
            x="0px" y="0px"
            width="820.35px" height="640.35px" viewBox="0 0 820.35 640.35" 
            onClick={obtenetID}
        >
            <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M435.98,399.86c-1.38-1.45-8.81-5.36-8.81-5.36
                c22.47,25.32,19.79,69,19.67,70.82c0-2.31-4.59-5.43-4.59-5.43c2.75,19.12-10.21,44.01-23.94,60.4
                c-4.68,5.62-9.46,10.23-13.73,13.27c-16.69,11.87-35.62,42.03-35.62,42.03c-6.15-28.56-31.89-45.01-48.09-60.12
                c-4.15-3.87-7.67-7.67-10.09-11.54c-11.82-19.05-9.02-41.52-9.02-41.52c-2.38,1.35-4.85,6.92-4.85,6.92
                c-3.15-51.73,14.82-66.86,14.82-66.86c-3.45,0.4-11.21,5.88-11.21,5.88c1.19-12.66,12.08-41.05,19.79-51.31
                c16.83-22.4,20.84-41.59,20.84-41.59c9.16,4.08,18.74,8.55,28.74,13.47h0.03c3.33,4.5,11.05,6.79,19.4,5.24
                c8.86-1.66,15.55-7.08,16.48-12.87c1.05,9.56,8.85,36.95,18.99,51.54C436.35,389.51,435.98,399.86,435.98,399.86z"/>
        </g>
        <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M384.76,309.58c3.22-0.61,6.34-0.64,9.14-0.19
                c6.25,1,11,4.36,11.87,9.02c-0.14,0.56-0.12,1.54,0.02,2.89c-0.94,5.78-7.63,11.21-16.48,12.87c-8.35,1.54-16.06-0.74-19.4-5.24
                c-0.82-1.07-1.35-2.29-1.61-3.58c-0.65-3.48,0.98-7.02,4.08-9.89C375.37,312.68,379.7,310.51,384.76,309.58z"/>
        </g>
        <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M405.77,318.41c-0.87-4.66-5.62-8.02-11.87-9.02l0.02-0.21
                c0,0,15.43-50.47,56.88-85.95c40.44-34.64,110.61-15.41,113.87-14.47c-3.17-0.56-67.2-10.28-143.04,89.32
                c-5.2,6.83-10.44,14.15-15.75,22.06C405.9,319.58,405.88,318.99,405.77,318.41z"/>
        </g>
        <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M369.88,328.92c-10-4.92-19.58-9.39-28.74-13.47
                c-9.42-4.19-18.39-7.97-26.95-11.35c-131.82-52.19-166.63-14.08-167.89-12.64c0.98-1.42,27.79-38.81,97.79-36.78
                c71.35,2.1,128.28,60.77,128.28,60.77c-3.1,2.87-4.73,6.41-4.08,9.89c0.26,1.3,0.79,2.52,1.61,3.58H369.88z"/>
        </g>
        <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M183.4,160.73c-23.15-32.4-31.78-69.54-29.84-72.61
                c20.26,2.29,40.14,19.07,52.54,31.8c7.93,8.16,12.82,14.64,12.82,14.64C203.56,139.64,183.4,160.73,183.4,160.73z"/>
        </g>
        <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M428.98,91.17c16.44-28.04,34.64-39.35,34.64-39.35
                s4.5,29.61-5.27,68.09c-12.84-4.9-38.86-9.84-38.86-9.84C422.48,103.06,425.69,96.79,428.98,91.17z"/>
        </g>
        <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M230.65,115.37c-11.05-4.29-24.55,4.55-24.55,4.55
                c-12.4-12.73-32.28-29.51-52.54-31.8c1.96-3.1,22.1-8.32,49.95,7.91C215.73,103.13,224.59,109.96,230.65,115.37z"/>
        </g>
        <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M401.68,95.39c10.42-16.73,34.01-48.16,61.93-43.57
                c0,0-18.2,11.3-34.64,39.35c-1.49-0.37-12.49-2.72-26.87,4.48L401.68,95.39z"/>
        </g>
        <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M484.81,551.92c33.8,8.88,36.78,23.89,33.31,33.62
                c-2.5,6.92-76.21-1.57-76.21-1.57c1.4-1.05,2.33-2.38,2.64-4.03c0.67-3.52-0.35-6.25-2.27-8.56
                C462.46,567.87,476,560.78,484.81,551.92z"/>
        </g>
        <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M442.29,571.38c1.91,2.31,2.94,5.04,2.27,8.56
                c-0.3,1.65-1.24,2.98-2.64,4.03c-6.81,5.2-24.41,4.03-31.45,4.66c-8.46,0.77-41.38-0.84-41.38-0.84
                c1.49-0.44-0.14-12.22-0.14-12.22s18.93-30.16,35.62-42.03c4.27-3.03,9.04-7.65,13.73-13.27c0,0-1.37,19.89,2.61,32.12
                C423.85,561.45,436.81,564.76,442.29,571.38z"/>
        </g>
        <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M294.08,570.73c5.13-6.2,17.69-11.42,23.14-15.95
                c7.18-5.96,3.64-39.33,3.64-39.33c16.2,15.11,41.94,31.56,48.09,60.12c0,0,1.63,11.77,0.14,12.22c-1.46,0.42-30.93,0.3-48.48,1.35
                c-7.69,0.44-13.92-0.14-18.58-1.72c-5.99-1.98-9.44-5.59-10.14-10.61C291.58,574.7,292.47,572.66,294.08,570.73z"/>
        </g>
        <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M302.03,587.42v0.02c0,0-77.67,4.83-79.87-2.21
                c-3.07-9.84,0.52-24.71,34.67-32.19l0.49-0.49c8.32,8.18,20.21,13.96,36.76,18.18c-1.61,1.93-2.5,3.96-2.19,6.08
                C292.58,581.83,296.03,585.44,302.03,587.42z"/>
        </g>
        <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M629.8,269.81c16.09,24.22,39.68,51.98,50.15,95.58
                c-15.39-38.56-54.83-50.43-54.83-50.43c4.11,14.01-0.74,37.09-0.74,37.09c-2.26-12.26-16.46-22.52-16.46-22.52
                c-2.61,5.01-4.94,27.41-4.94,27.41c-11.68-10.26-14.96-33.24-14.96-33.24c-22.99,21.08-16.2,87.35-16.2,87.35
                c-38.16-33.11-28.25-88.26-28.25-88.26c-11.98,4.38-19.42,17.51-19.42,17.51c-4.48-7-2.43-31.48-2.43-31.48
                c11.47-34.66,39.88-67.72,99.03-84.92C620.74,223.91,609.15,238.71,629.8,269.81z"/>
        </g>
        <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M685.24,406.85c1.73,68.93-75.62,161.15-191.38,132.52
                c2.21-4.41,3.69-9,4.57-13.64c0,0,32.89-3.05,45.92-27.83c13.03-24.78,9.65-43.64-11.59-76.65
                c-12.05-18.74-26.13-66.95-11.05-112.43c0,0-2.05,24.48,2.43,31.48c0,0,7.44-13.13,19.42-17.51c0,0-9.91,55.15,28.25,88.26
                c0,0-6.79-66.28,16.2-87.35c0,0,3.29,22.99,14.96,33.24c0,0,2.33-22.4,4.94-27.41c0,0,14.2,10.25,16.46,22.52
                c0,0,4.85-23.08,0.74-37.09c0,0,39.44,11.86,54.83,50.43C682.95,377.81,684.87,391.54,685.24,406.85z"/>
        </g>
        <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M427.16,394.5c0,0,7.44,3.92,8.81,5.36c0,0,0.37-10.35-11.2-27.02
                c-10.14-14.6-17.94-41.98-18.99-51.54c0.07-0.39,0.09-0.77,0.09-1.14v-0.02c5.31-7.9,10.56-15.22,15.75-22.06
                c0,0,9.51,52.62,26.97,83.71c12.38,22.03,33.15,75.37,43.06,96.72c4.73,10.21,10.25,29.02,6.76,47.23
                c-0.88,4.64-2.36,9.23-4.57,13.64c-2.21,4.43-5.17,8.65-9.04,12.54c-8.81,8.86-22.35,15.95-42.52,19.46
                c-5.48-6.62-18.44-9.93-21.37-18.97c-3.98-12.24-2.61-32.12-2.61-32.12c13.73-16.39,26.69-41.29,23.94-60.4
                c0,0,4.59,3.12,4.59,5.43C446.95,463.5,449.63,419.82,427.16,394.5z"/>
        </g>
        <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M296.9,469.33c0,0,2.47-5.57,4.85-6.92c0,0-2.8,22.47,9.02,41.52
                c2.43,3.87,5.95,7.67,10.09,11.54c0,0,3.54,33.36-3.64,39.33c-5.45,4.53-18.02,9.75-23.14,15.95c-16.55-4.22-28.44-10-36.76-18.18
                c-12.87-12.63-17.11-31.01-16.55-58.09c0.93-44.62,29.7-94.5,42.75-116.56c22.73-38.44,30.68-73.8,30.68-73.8
                c8.55,3.38,17.53,7.16,26.95,11.35c0,0-4.01,19.19-20.84,41.59c-7.72,10.26-18.6,38.65-19.79,51.31c0,0,7.76-5.48,11.21-5.88
                C311.72,402.47,293.75,417.6,296.9,469.33z"/>
        </g>
        <g>
            <path fill="#FFFFFF" stroke="#000000" stroke-miterlimit="10" d="M393.9,309.39c-2.8-0.44-5.92-0.42-9.14,0.19
                c-5.06,0.93-9.39,3.1-12.38,5.87c0,0-56.93-58.67-128.28-60.77c-70-2.03-96.81,35.37-97.79,36.78c-0.05,0.02-0.05,0.05-0.05,0.05
                c-9.09-15.22,1.03-49.47,1.03-49.47c-6.97,2.98-14.69,13.47-14.69,13.47c3.66-56.9,50.8-94.78,50.8-94.78s20.16-21.1,35.52-26.18
                c0,0-4.89-6.48-12.82-14.64c0,0,13.5-8.84,24.55-4.55c7.79,6.88,11.05,11.49,11.05,11.49s40.63-18.61,82.99-22.99
                c52.57-5.45,71.26,1.54,71.26,1.54s2.05-4.08,5.73-10.03l0.42,0.26c14.38-7.2,25.38-4.85,26.87-4.48
                c-3.28,5.62-6.5,11.89-9.48,18.91c0,0,26.02,4.94,38.86,9.84c0,0,41.29,16.48,57.46,25.92c16.15,9.42,38,26.25,54.43,49.75
                c0,0-12.45-4.64-21.19-5.43c0,0,11.21,10.4,15.73,18.63c0,0-0.05,0-0.12-0.02c-3.26-0.93-73.43-20.17-113.87,14.47
                c-41.45,35.48-56.88,85.95-56.88,85.95L393.9,309.39z"/>
        </g>
        <g>
            <g>
                <path fill="#231F20" d="M273.93,242.58c0,8.07-6.54,14.61-14.61,14.61c-8.07,0-14.61-6.54-14.61-14.61
                    c0-8.07,6.54-14.62,14.61-14.62C267.39,227.96,273.93,234.51,273.93,242.58z"/>
            </g>
            <g>
                <path fill="#FFFFFF" d="M268.3,237.33c0,2.06-1.67,3.73-3.73,3.73c-2.06,0-3.73-1.67-3.73-3.73c0-2.06,1.67-3.73,3.73-3.73
                    C266.63,233.6,268.3,235.27,268.3,237.33z"/>
            </g>
        </g>
        <g>
            <g>
                <path fill="#231F20" d="M445.3,223.91c0,8.07-6.54,14.61-14.61,14.61c-8.07,0-14.62-6.54-14.62-14.61
                    c0-8.07,6.54-14.62,14.62-14.62C438.76,209.29,445.3,215.84,445.3,223.91z"/>
            </g>
            <g>
                <path fill="#FFFFFF" d="M439.67,218.66c0,2.05-1.67,3.73-3.73,3.73c-2.06,0-3.73-1.67-3.73-3.73c0-2.06,1.67-3.73,3.73-3.73
                    C438,214.93,439.67,216.6,439.67,218.66z"/>
            </g>
        </g>

        </svg> */}
            <div className='botones'>
                <div className='btn'>
                    <img src={btnReiniciar} alt="imagen" onClick={reiniciar}/>
                </div>

                <div className='btn'>
                    <img src={btnDescargar} alt="imagen" onClick={download} />
                </div>
            </div>
        </div>
    )
}

export default DibujoPrueba