import React from 'react';
import Header from '../components/header/Header';
import PaletaColores from '../components/paleta/PaletaColores';
import {Outlet} from 'react-router-dom';

import './Layout.scss';

const Layout = () => {
    return (
        <div className='content_layuot'>
            <header>
                <Header />
            </header>
            <aside>
                <PaletaColores />
            </aside>
            <main>
                <Outlet />
            </main>
            {/* <footer>
                <Opciones />
            </footer> */}
        </div>
    )
}

export default Layout